import * as tslib_1 from "tslib";
import { Apollo } from 'apollo-angular';
import { GENERATE_S3_POLICY_MUTATION, POLICY_SETTINGS_QUERY, } from '@graphql';
import { toGqlMutation, toGqlQuery } from '@utils/json-util';
import { map, mergeMap } from 'rxjs/operators';
import { BlockUiService } from '@services';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "./block-ui.service";
var S3Service = (function () {
    function S3Service(apollo, blockUiService) {
        this.apollo = apollo;
        this.blockUiService = blockUiService;
    }
    S3Service.prototype.reloadPolicySettings = function (force) {
        var _this = this;
        if (force === void 0) { force = false; }
        if (!force && this.policySettings) {
            return;
        }
        return this.apollo.query({
            query: toGqlQuery('PolicySettingsQuery', POLICY_SETTINGS_QUERY),
        }).pipe(map(function (res) {
            _this.policySettings = res.data.policySettings;
            return !!_this.policySettings;
        }));
    };
    S3Service.prototype.getS3BucketUrl = function () {
        var _a = this.policySettings, region = _a.region, bucket = _a.bucket;
        return "https://s3.dualstack." + region + ".amazonaws.com/" + bucket;
    };
    S3Service.prototype.uploadFile = function (file, bucketPath, blockUi) {
        var _this = this;
        if (blockUi === void 0) { blockUi = true; }
        return this.generateS3Policy().pipe(mergeMap(function (policy) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.uploadFileToS3(bucketPath, policy, file, blockUi)];
                    case 1:
                        _a.sent();
                        return [2, this.getS3FilePath(bucketPath, policy.identifier, file.name)];
                }
            });
        }); }));
    };
    S3Service.prototype.generateS3Policy = function () {
        return this.apollo.mutate({
            mutation: toGqlMutation('GenerateS3Policy', GENERATE_S3_POLICY_MUTATION),
            variables: { numberOfPolicies: 1 },
        }).pipe(map(function (res) { return res.data.generateS3Policy[0]; }));
    };
    S3Service.prototype.uploadFileToS3 = function (s3BucketPath, policy, file, blockUi) {
        var s3Url = this.getS3BucketUrl();
        var s3FilePath = this.getS3FilePath(s3BucketPath, policy.identifier, file.name);
        var filename = this.sanitizeFilename(file.name);
        var formData = this.createFormData([
            ['key', s3FilePath],
            ['AWSAccessKeyId', policy.key],
            ['acl', 'public-read'],
            ['policy', policy.policy],
            ['signature', policy.signature],
            ['Content-Type', file.type],
            ['Content-Disposition', "filename=\"" + filename + "\""],
            ['success_action_status', '201'],
            ['file', file],
        ]);
        return this.httpRequest('POST', s3Url, formData, blockUi);
    };
    S3Service.prototype.getS3FilePath = function (s3BucketPath, identifier, filename) {
        var extension = this.fileExtension(filename);
        return s3BucketPath + "/" + identifier + extension;
    };
    S3Service.prototype.fileExtension = function (filename) {
        var lastDot = filename.lastIndexOf('.');
        if (lastDot < 0) {
            return '';
        }
        return filename.substr(lastDot);
    };
    S3Service.prototype.sanitizeFilename = function (filename) {
        return filename.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };
    S3Service.prototype.createFormData = function (entries) {
        var formData = new FormData();
        for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
            var _a = entries_1[_i], key = _a[0], value = _a[1];
            formData.append(key, value);
        }
        return formData;
    };
    S3Service.prototype.httpRequest = function (method, url, formData, blockUi) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (blockUi) {
                _this.blockUiService.increment();
            }
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState !== 4) {
                    return;
                }
                else if (xhr.status >= 200 && xhr.status < 400) {
                    resolve(xhr.response);
                }
                else {
                    reject(xhr.response);
                }
                if (blockUi) {
                    _this.blockUiService.decrement();
                }
            };
            xhr.open(method, url, true);
            xhr.send(formData);
        });
    };
    S3Service.ngInjectableDef = i0.defineInjectable({ factory: function S3Service_Factory() { return new S3Service(i0.inject(i1.Apollo), i0.inject(i2.BlockUiService)); }, token: S3Service, providedIn: "root" });
    return S3Service;
}());
export { S3Service };
