import { AlertMessage } from '@models';
import { AlertMessageService, AuthService } from '@services';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { AbstractInterceptor } from './abstract.interceptor';
import { Location } from '@angular/common';

@Injectable()
export class AuthInterceptor extends AbstractInterceptor {

  constructor(
    private readonly authService: AuthService,
    private readonly alertMessageService: AlertMessageService,
    private readonly location: Location,
  ) {
    super();
  }

  going(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.getAuthToken();

    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return req;
  }

  returning(res: HttpResponse<any>): HttpResponse<any> {
    if (res.status === 401) {
      this.alertMessageService.showAfterNavigationEnd(
        AlertMessage.error('Houve um erro de autenticação. Por favor, faça login novamente.'),
      );
      this.authService.logout(this.location.path(true));
    } else if (res.status === 403) {
      this.alertMessageService.showFlexible(
        AlertMessage.error('Parece que você não tem permissão para fazer isso...'),
      );
    }
    return res;
  }
}
