import { types } from 'typed-graphqlify';

export const GYM_UNITY = {
    id: types.number,
    name: types.string,

    activeGymUnityContract: {
        contract: {
            id: types.number,
            name: types.string,
            path: types.string,
        },

        auditInformation: {
            inclusionDate: types.string,
            modificationDate: types.string,
        },
    },
};

export type GymUnity = typeof GYM_UNITY;

export const GYM_UNITY_QUERY = {
    gyms : [
        GYM_UNITY,
    ],
};

export type GymUnityQuery = typeof GYM_UNITY_QUERY;
