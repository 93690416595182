import { Purchase } from '@graphql';
import { Injectable } from '@angular/core';
import { PurchaseService } from '@services';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CancelPurchaseResolver implements Resolve<Observable<Purchase | boolean>> {
    constructor(
        private readonly purchaseService: PurchaseService,
        private readonly router: Router,
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        const { clientId, purchaseId } = route.params;

        return this.purchaseService
            .purchase(purchaseId)
            .pipe(
                mergeMap((purchase: Purchase) => {
                    if (!purchase || purchase.isCanceled || !purchase.userCancelPermission.canCancel) {
                        return this.goToClientPage(clientId);
                    } else {
                        return of(purchase);
                    }
                }),
                catchError(() => this.goToClientPage(clientId)),
            );
    }

    private goToClientPage(clientId: any) {
        const promise = this.router.navigate(['/client', clientId]);
        return from(promise).pipe(map(() => null));
    }
}
