<div class="blockquote-wrapper" [@flyInOut] *ngFor="let msg of messages">

  <div class="blockquote-inside-wrapper">
    <blockquote [class]="msg.type | alertTypeToClass">
      <span class="content">{{msg.msg}}</span>
      <div class="close" (click)="close(msg)"><span aria-hidden="true">×</span><span class="sr-only">Close</span></div>
    </blockquote>
  </div>
  <div class="spacer"></div>
</div>
