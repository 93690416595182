import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Resolve } from '@angular/router';
import { PlanService } from '@services';
import { Plan } from '@graphql';

@Injectable({
  providedIn: 'root',
})
export class PlanResolver implements Resolve<Observable<Plan[]>> {
  constructor(
    private readonly planService: PlanService,
  ) {}

  resolve() {
    return this.planService.list();
  }
}
