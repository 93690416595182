import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AlertMessageService = (function () {
    function AlertMessageService(platformId, router) {
        var _this = this;
        this.platformId = platformId;
        this.router = router;
        this.emitter = new Subject();
        this.cleanMessages = new Subject();
        this.stackedMessages = [];
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationStart || event instanceof NavigationEnd; }))
            .subscribe(function (event) { return _this.lastRouterEvent = event; });
    }
    AlertMessageService.genericError = function (reason) {
        var arr = reason.split(' ');
        return {
            code: arr.splice(0, 1).join(' '),
            message: arr.join(' '),
        };
    };
    AlertMessageService.prototype.consume = function () {
        var temp = this.stackedMessages;
        this.stackedMessages = [];
        return temp;
    };
    AlertMessageService.prototype.showAfterNavigationEnd = function (msg) {
        var _a;
        if (!Array.isArray(msg)) {
            msg = [msg];
        }
        (_a = this.stackedMessages).push.apply(_a, msg);
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    };
    AlertMessageService.prototype.showNow = function (msg) {
        if (!Array.isArray(msg)) {
            msg = [msg];
        }
        this.emitter.next(msg);
    };
    AlertMessageService.prototype.showFlexible = function (msg) {
        if (this.lastRouterEvent instanceof NavigationStart) {
            this.showAfterNavigationEnd(msg);
        }
        else if (this.lastRouterEvent instanceof NavigationEnd) {
            this.showNow(msg);
        }
    };
    AlertMessageService.prototype.clear = function () {
        this.cleanMessages.next();
    };
    AlertMessageService.ngInjectableDef = i0.defineInjectable({ factory: function AlertMessageService_Factory() { return new AlertMessageService(i0.inject(i0.PLATFORM_ID), i0.inject(i1.Router)); }, token: AlertMessageService, providedIn: "root" });
    return AlertMessageService;
}());
export { AlertMessageService };
