import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ng-block-ui/ng-block-ui.ngfactory";
import * as i3 from "ng-block-ui";
import * as i4 from "@angular/router";
import * as i5 from "./components/network-error/network-error.component.ngfactory";
import * as i6 from "./components/network-error/network-error.component";
import * as i7 from "./app.component";
import * as i8 from "./services/block-ui.service";
import * as i9 from "./services/breadcrumb.service";
import * as i10 from "ngx-bootstrap/modal";
import * as i11 from "ngx-bootstrap/datepicker";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "spinner-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "double-bounce1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "double-bounce2"]], null, null, null, null, null))], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { networkErrorComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "block-ui", [], null, null, null, i2.View_BlockUIComponent_0, i2.RenderType_BlockUIComponent)), i1.ɵdid(2, 114688, null, 0, i3.BlockUIComponent, [i3.ɵc], { template: [0, "template"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_AppComponent_1)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-network-error", [], null, null, null, i5.View_NetworkErrorComponent_0, i5.RenderType_NetworkErrorComponent)), i1.ɵdid(7, 49152, [[1, 4], ["network_error", 4]], 0, i6.NetworkErrorComponent, [], null, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5); _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i7.AppComponent, [i8.BlockUiService, i9.BreadcrumbService, i10.BsModalService, i11.BsLocaleService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
