import { of, Subject, Subscription } from 'rxjs';

export function safeUnsubscribe(sub: Subscription): void {
  if (sub) { sub.unsubscribe(); }
}

export function safeComplete(subject: Subject<any>): void {
  if (subject) { subject.complete(); }
}

export function copyOf<T = any>(obj: T) {
  try {
    return of<T>(JSON.parse(JSON.stringify(obj)));
  } catch (e) {
    console.log('Erro no parse de JSON:', obj);
    return null;
  }
}
