import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { RegistrationSheet, RegistrationSheetInfoItem, RegistrationSheetTableItem } from '@models';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  // Necessário para evitar XSS
  private escapeTextToHtml(unescapedText: string) {
    const span = document.createElement('span');
    const textNode = document.createTextNode(String(unescapedText));
    span.appendChild(textNode);
    return span.innerHTML;  // CUIDADO span.innerText retorna a string original sem escapamento
  }

  private createRegistrationSheetItem(item: RegistrationSheetInfoItem): string {
    // Checagem para evitar XSS
    const size = ['sm', 'md'].indexOf(item.size) === -1 ? 'sm' : item.size;

    const label = item.label ? this.escapeTextToHtml(item.label) : '';
    const data = item.data ? this.escapeTextToHtml(item.data) : '-';

    return `
      <div class="item-${size} ${item.hidden ? 'invisible' : ''}">
        <div class="font-title">${label}</div>
        <div>${data}</div>
      </div>
    `;
  }

  private createTableItem(item: RegistrationSheetTableItem): string {
    const plan = this.escapeTextToHtml(item.plan);
    const contractDate = this.escapeTextToHtml(item.contractDate);
    const expirationDate = this.escapeTextToHtml(item.expirationDate);
    const totalValue = this.escapeTextToHtml(item.totalValue);

    return `
      <tr>
        <td>${plan}</td>
        <td>${contractDate}</td>
        <td>${expirationDate}</td>
        <td>${totalValue}</td>
      </tr>
    `;
  }

  private formatCurrencyBrl(value: number) {
    const symbol = getCurrencySymbol('BRL', 'wide');
    return formatCurrency(value, 'pt-BR', symbol, 'BRL', '1.2-2');
  }

  printRegistrationSheet(printOnOpen: boolean, data: RegistrationSheet) {
    const timestamp = moment().format('DD/MM/YYYY - HH:mm');
    const gymUnityName = this.escapeTextToHtml(data.gymUnityName);
    const formattedTotalValue = this.formatCurrencyBrl(+data.service.totalValue);

    const formatDate = (date: string) => moment(date).utc().format('DD/MM/YYYY');

    const basicData: RegistrationSheetInfoItem[] = [
      { size: 'sm', label: 'Matrícula', data: data.basicData.matricula },
      { size: 'sm', label: 'Data de Cadastro', data: formatDate(data.basicData.dataCadastro) },
      { size: 'sm', label: 'Estado Civil', data: data.basicData.estadoCivil },
      { size: 'sm', label: 'Sexo', data: data.basicData.gender },

      { size: 'md', label: 'Nome', data: data.basicData.name },
      { size: 'md', label: 'Profissão', data: data.basicData.profession },

      { size: 'md', label: 'Nome Reduzido', data: data.basicData.shortenedName },
      { size: 'md', label: 'Nome da Mãe', data: data.basicData.motherName },

      { size: 'sm', label: 'R.G.', data: data.basicData.rg },
      { size: 'sm', label: 'Órgão Expedidor', data: data.basicData.orgaoExpedidorRg },
      { size: 'md', label: 'Nome do Pai', data: data.basicData.fatherName },

      { size: 'sm', label: 'Data de Nascimento', data: formatDate(data.basicData.birthDate) },
      { size: 'sm', label: 'CPF', data: data.basicData.cpf },
      { size: 'md', label: 'Nome do Responsável', data: data.basicData.responsavelName },

      { size: 'sm', label: 'Natural de', data: data.basicData.naturalDe },
      { size: 'sm', label: 'Nacionalidade', data: data.basicData.nationality },
      { size: 'md', label: 'CPF do Responsável', data: data.basicData.responsavelCpf },

      { size: 'sm', label: 'Estrangeiro', data: data.basicData.isForeigner ? 'Sim' : 'Não' },
      { size: 'sm', label: 'Doc. Ident. Estrangeiro', data: data.basicData.foreignDocument },
    ];

    const { address } = data.contact;

    const contact: RegistrationSheetInfoItem[] = [
      { size: 'md', label: 'Endereço', data: address.endereco },
      { size: 'md', label: 'E-mail Principal', data: data.contact.mainEmail },

      { size: 'sm', label: 'Número', data: address.numero },
      { size: 'sm', label: 'Complemento', data: address.complemento },
      { size: 'sm', label: 'Telefone Principal', data: data.contact.mainPhone },
      { size: 'sm', label: 'Outro telefone', data: data.contact.otherPhone },

      { size: 'sm', label: 'CEP', data: address.cep },
      { size: 'sm', label: 'Bairro', data: address.bairro },
      { size: 'sm', label: 'Contato preferencial', data: data.contact.preferentialContact },
      { size: 'sm', label: 'Telefone', data: data.contact.preferentialContactPhone, hidden: !data.contact.preferentialContact },

      { size: 'sm', label: 'Cidade', data: address.cidade },
      { size: 'sm', label: 'Estado', data: address.estado },
      { size: 'sm', label: 'Outro contato', data: data.contact.otherContact },
      { size: 'sm', label: 'Telefone', data: data.contact.otherContactPhone, hidden: !data.contact.otherContact },
    ];

    const tableData: RegistrationSheetTableItem = {
      plan: data.service.plan,
      contractDate: formatDate(data.service.contractDate),
      expirationDate: formatDate(data.service.expirationDate),
      totalValue: formattedTotalValue,
    };

    const basicDataItems = basicData.map(item => this.createRegistrationSheetItem(item)).join('');
    const contactItems = contact.map(item => this.createRegistrationSheetItem(item)).join('');
    const tableItems = this.createTableItem(tableData);

    const body = `
      <div class="header banner-title">Ficha Cadastral do Cliente</div>
      <div class="content">
        <div class="print-header">
          <span class="font-title">Unidade: ${gymUnityName}</span>
          <span>Impresso em: ${timestamp}</span>
        </div>
        <div class="section">
          <div class="section-header banner-title">Dados Básicos</div>
          <div class="section-body">${basicDataItems}</div>
        </div>
        <div class="section">
          <div class="section-header banner-title">Contato</div>
          <div class="section-body">${contactItems}</div>
        </div>
        <div class="section">
          <div class="section-header banner-title">Serviço Contratado</div>
          <div class="section-body services-table">
            <table>
              <thead>
                <tr>
                  <th class="font-title">Plano</th>
                  <th class="font-title">Data de Contratação</th>
                  <th class="font-title">Data de Vencimento</th>
                  <th class="font-title">Valor Total</th>
                </tr>
              </thead>
              <tbody>${tableItems}</tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="footer"></div>
    `;

    const style = `
      *, *:before, *:after {
        box-sizing: border-box;
      }
      body {
        height: 842px;
        width: 595px;
        margin: auto;
        position: relative;
        font-family: Lato;
      }
      .invisible {
        visibility: hidden;
      }
      .font-title {
        font-family: Oswald;
        font-weight: 500;
      }
      .banner-title {
        color: #ffffff;
        text-shadow: 0 0 #ffffff !important;

        text-transform: uppercase;
        font-family: Oswald;
        font-weight: 500;

        display: flex;
        align-items: center;
        justify-content: center;
      }
      .header {
        height: 62px;
        background-image: linear-gradient(to bottom, #282c30, #51575e);
        font-size: 20px;
      }
      .header:before {
        content: url("assets/bt/logo/print-logo.svg");
        align-self: start;
        position: absolute;
        left: 6px;  // o SVG já tem 8px de padding, totalizando os 14px do design
      }
      .content {
        min-height: 740px;
        padding: 13px 14px 0px 14px;
        background-image: url("assets/common/registration-sheet-bg.png");
      }
      .footer {
        height: 40px;
        background-color: #282c30;
        background-image: url("assets/bt/logo/footer-logo.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
      .print-header {
        font-size: 14px;
        padding: 6px 10px 4px 10px;
        border: 1px solid #e0e1e2;
        background-color: white;

        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
      .section {
        padding-bottom: 12px;
      }
      .section-header {
        height: 31px;
        background-color: #71c5a5;
        font-size: 16px;
      }
      .section-body {
        padding: 10px 30px 0px 30px;
        border: 1px solid #e0e1e2;
        background-color: white;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        display: flex;
        flex-wrap: wrap;
      }
      .section-body.services-table {
        padding: 10px 10px 27px 10px;
      }
      .item-sm, .item-md {
        flex-grow: 1;
        font-size: 10.5px;
        margin-bottom: 10px;
      }
      .item-sm {
        flex-basis: 25%;
      }
      .item-md {
        flex-basis: 50%;
      }
      table {
        width: 100%;
        border-collapse: collapse;
      }
      tr {
        font-family: Lato;
        font-weight: 400;
      }
      tbody > tr:nth-child(odd) {
        background-color: #f9f9f9;
      }
      th, td {
        width: 25%;
        text-align: left;
      }
      th, tbody td {
        border-bottom: 1px solid #e0e1e2;
        font-size: 10px;
      }
      th {
        padding: 3px 5px 4px 5px;
      }
      td {
        padding: 7px 5px 7px 5px;
      }
    `;

    const printStyle = `
      @page {
        margin: 0;
      }
      html {
        padding: 10px;
        padding-top: 30px;
      }
      body {
        width: 100%;
        color: black !important;
      }
      .header {
        background-image: unset;
      }
      .footer {
        display: none;
      }
      .content {
        background-image: none;
      }
      .section-header {
        color: black !important;
        box-shadow: inset 0 0 0 1000px #f9f9f9;
        border: 1px solid #e0e1e2;
      }
      tbody > tr:nth-child(odd) {
        box-shadow: inset 0 0 0 1000px #f9f9f9;
      }
    `;

    const width = screen.availWidth;
    const height = screen.availHeight;

    const popup = window.open('', '_blank', `top=0,left=0,height=${height}px,width=${width}px`);
    popup.document.open();
    popup.document.write(`
      <html lang="bt-BR">
        <head>
          <meta charset="utf-8">
          <base href="/">
          <title>Ficha Cadastral do Cliente</title>
          <link href="https://fonts.googleapis.com/css?family=Oswald:500" rel="stylesheet" type="text/css">
          <link href="https://fonts.googleapis.com/css?family=Lato:400" rel="stylesheet" type="text/css">
          <style>${style}</style>
          <style media="print">${printStyle}</style>
        </head>
        <body ${printOnOpen ? 'onload="window.print()"' : ''} >
          ${body}
        </body>
      </html>`,
    );
    popup.document.close();
  }
}
