import { safeUnsubscribe } from '@utils/rxjs-util';
import { AuthService, BreakpointsService, ScreenSize } from '@services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UserDetails } from '@graphql';
import { PermissionEnum } from '@models';

interface SimpleLink {
  text: string;
  url?: string;
  permission?: PermissionEnum[];
  children?: SimpleLink[];
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  public readonly appVersion: string = '-1';

  userDetails$: Observable<UserDetails>;

  navbarItems: SimpleLink[] = [
    {
      text: 'Cliente',
      url: '/client',
      permission: [PermissionEnum.LIST_CLIENTS_SCREEN],
    },
    {
      text: 'Configurações',
      url: '/settings',
      permission: [PermissionEnum.SETTINGS_SCREEN],
    },
    {
      text: 'Relatórios',
      permission: [PermissionEnum.REPORTS_SCREEN],
      children: [
        {
          text: 'Vendas',
          url: '/reports/purchase',
        },
        {
          text: 'Cancelamentos',
          url: '/reports/rescission',
        },
      ],
    },
  ];

  public userMenuOpened = false;
  public mouseOverUserMenu = false;
  private _menuOpened = false;
  private _screenLG = false;
  private _expanded = false;

  get menuOpened() { return this._menuOpened; }
  set menuOpened(val: boolean) {
    this._menuOpened = val;
    this.recalculateExpanded();
  }
  get screenLG() { return this._screenLG; }
  set screenLG(val: boolean) {
    this._screenLG = val;
    this.recalculateExpanded();
  }
  get expanded() { return this._expanded; }

  private subsBreakpoints: Subscription;

  constructor(
    public readonly authService: AuthService,
    public readonly breakpointsService: BreakpointsService,
  ) {
    this.subsBreakpoints = this.breakpointsService.emitter.subscribe(
      val => this.screenLG = val >= ScreenSize.lg,
    );

    try {
      this.appVersion = document.body.dataset.appVersion || '-1';
    } catch (_) {}
  }

  ngOnInit() {
    this.userDetails$ = this.authService.getUser();
  }

  ngOnDestroy() {
    safeUnsubscribe(this.subsBreakpoints);
  }

  logout() {
    this.menuOpened = this.userMenuOpened = false;
    this.authService.logout();
  }

  onClickHome() {
    this.menuOpened = false;
  }

  onExpandedChange(val: boolean) {
    if (!this.screenLG) {
      this.menuOpened = val;
    }
  }

  onBlur() {
    if (this.screenLG && !this.mouseOverUserMenu) {
      this.menuOpened = this.userMenuOpened = false;
    }
  }

  recalculateExpanded() {
    this._expanded = this.menuOpened || this.screenLG;
  }
}
