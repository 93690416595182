import * as tslib_1 from "tslib";
var _a;
import { alias, types } from 'typed-graphqlify';
import { getSearchResults } from '@graphql/search.graphql';
export var CLOSEST_EXPIRATION_PURCHASE = (_a = {
        id: types.number,
        usedPasses: types.number,
        totalPasses: types.number
    },
    _a[alias('isActive', 'isActive(timezone: $timezone)')] = types.boolean,
    _a[alias('status', 'status(timezone: $timezone)')] = types.string,
    _a.plan = {
        name: types.string,
    },
    _a);
export var CLIENT = {
    enrollmentId: types.number,
    fullName: types.string,
    documentNumber: types.string,
    closestExpirationPurchase: CLOSEST_EXPIRATION_PURCHASE,
};
CLIENT['closestExpirationPurchase(timezone: $timezone)'] = CLIENT.closestExpirationPurchase;
delete CLIENT.closestExpirationPurchase;
export var SEARCH_CLIENTS_QUERY = {
    __params: { $searchWord: 'String', $entriesByPage: 'Int', $ignoreIds: '[Int!]', $timezone: 'String!' },
    searchClient: tslib_1.__assign({ __params: { searchWord: '$searchWord', entriesByPage: '$entriesByPage', ignoreIds: '$ignoreIds' } }, getSearchResults(CLIENT)),
};
