import { AlertTypeToClassPipe } from './alert-type-to-class.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormErrorComponent } from './form-error.component';

@NgModule({
  declarations: [
    FormErrorComponent,
    AlertTypeToClassPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [FormErrorComponent],
})
export class FormErrorModule {}
