import { Router, } from '@angular/router';
import { AuthService } from '@services';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
var CanGuard = (function () {
    function CanGuard(authService, router) {
        this.authService = authService;
        this.router = router;
        this.canActivateChild = this.canActivate;
    }
    CanGuard.prototype.canActivate = function (route) {
        var _this = this;
        return this.authService.getUser().pipe(map(function (user) {
            var can = route.data.can;
            if (!can || !can.length) {
                return true;
            }
            if (user && user.profile && user.profile.permissions) {
                var permissions_1 = user.profile.permissions.map(function (p) { return p.code; });
                if (can.some(function (p) { return permissions_1.includes(p); })) {
                    return true;
                }
            }
            return _this.router.parseUrl('/unauthorized');
        }));
    };
    CanGuard.ngInjectableDef = i0.defineInjectable({ factory: function CanGuard_Factory() { return new CanGuard(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: CanGuard, providedIn: "root" });
    return CanGuard;
}());
export { CanGuard };
