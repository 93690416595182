import { getSearchResults } from './search.graphql';
import { CONTRACT } from './contract.graphql';
import { ContractSearchType } from '@models';

export const SEARCH_CONTRACT_QUERY = {
    __params: {
      $searchWord: 'String',
      $startInclusionDate: 'Date',
      $endInclusionDate: 'Date',
      $entriesByPage: 'Int',
      $ignoreIds: '[Int!]',
      $searchType: 'ContractSearchType',
    },
    searchContract: {
        __params: {
          searchWord: '$searchWord',
          startInclusionDate: '$startInclusionDate',
          endInclusionDate: '$endInclusionDate',
          entriesByPage: '$entriesByPage',
          ignoreIds: '$ignoreIds',
          searchType: '$searchType',
        },
        ...getSearchResults(CONTRACT),
    },
};

export type SearchContractQuery = typeof SEARCH_CONTRACT_QUERY;

export interface SearchContractParams {
  searchWord?: string;
  startInclusionDate?: string;
  endInclusionDate?: string;
  entriesByPage?: number;
  ignoreIds?: number[];
  searchType?: ContractSearchType;
}
