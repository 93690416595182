import { environment } from '@env';
var genericEndpointInterpolation = function (baseurl, path, params) {
    if (params === void 0) { params = []; }
    var interpoled = params.reduce(function (old, curr, i) { return old.split("{{" + i + "}}").join(curr.toString()); }, path);
    return baseurl + interpoled;
};
var ɵ0 = genericEndpointInterpolation;
export var legacy = function (path) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return genericEndpointInterpolation(environment.legacy.baseUrl, path, params);
};
export var back = function (path) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return genericEndpointInterpolation(environment.back.baseUrl, path, params);
};
export { ɵ0 };
