<block-ui [template]="loading">
  <router-outlet></router-outlet>
</block-ui>

<ng-template #loading>
  <div class="spinner-wrapper">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
</ng-template>

<app-network-error #network_error></app-network-error>
