import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alertTypeToClass',
})
export class AlertTypeToClassPipe implements PipeTransform {
  public static classes: { [key: string]: string} = {
    S: 'success',
    E: 'error',
    W: 'warning',
    I: 'info',
    D: 'debug',
  };

  transform(type: string): string {
    return AlertTypeToClassPipe.classes[type];
  }
}
