import * as i0 from "./form-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alert-type-to-class.pipe";
import * as i3 from "@angular/common";
import * as i4 from "./form-error.component";
import * as i5 from "@angular/router";
import * as i6 from "../../services/alert-message.service";
var styles_FormErrorComponent = [i0.styles];
var RenderType_FormErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormErrorComponent, data: { "animation": [{ type: 7, name: "flyInOut", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { height: "unset" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { height: "0" }, offset: null }, { type: 4, styles: null, timings: 250 }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { height: "0" }, offset: null }, timings: 250 }], options: null }], options: {} }] } });
export { RenderType_FormErrorComponent as RenderType_FormErrorComponent };
function View_FormErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "blockquote-wrapper"]], [[24, "@flyInOut", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "blockquote-inside-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "blockquote", [], [[8, "className", 0]], null, null, null, null)), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "spacer"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.type)); _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit.msg; _ck(_v, 5, 0, currVal_2); }); }
export function View_FormErrorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.AlertTypeToClassPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormErrorComponent_1)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FormErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-error", [], null, null, null, View_FormErrorComponent_0, RenderType_FormErrorComponent)), i1.ɵdid(1, 245760, null, 0, i4.FormErrorComponent, [i5.Router, i6.AlertMessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormErrorComponentNgFactory = i1.ɵccf("app-form-error", i4.FormErrorComponent, View_FormErrorComponent_Host_0, { messages: "messages", autoRetrieve: "autoRetrieve" }, {}, []);
export { FormErrorComponentNgFactory as FormErrorComponentNgFactory };
