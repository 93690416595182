import { types } from 'typed-graphqlify';

export const PLAN = {
  id: types.number,
  name: types.string,
};

export type Plan = typeof PLAN;

export const PLAN_QUERY = {
  plans: [PLAN],
};

export type PlanQuery = typeof PLAN_QUERY;
