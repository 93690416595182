import { safeUnsubscribe } from '@utils/rxjs-util';
import { AuthService, BreakpointsService } from '@services';
import { OnDestroy, OnInit } from '@angular/core';
var NavbarComponent = (function () {
    function NavbarComponent(authService, breakpointsService) {
        var _this = this;
        this.authService = authService;
        this.breakpointsService = breakpointsService;
        this.appVersion = '-1';
        this.navbarItems = [
            {
                text: 'Cliente',
                url: '/client',
                permission: ["LIST_CLIENTS_SCREEN"],
            },
            {
                text: 'Configurações',
                url: '/settings',
                permission: ["SETTINGS_SCREEN"],
            },
            {
                text: 'Relatórios',
                permission: ["REPORTS_SCREEN"],
                children: [
                    {
                        text: 'Vendas',
                        url: '/reports/purchase',
                    },
                    {
                        text: 'Cancelamentos',
                        url: '/reports/rescission',
                    },
                ],
            },
        ];
        this.userMenuOpened = false;
        this.mouseOverUserMenu = false;
        this._menuOpened = false;
        this._screenLG = false;
        this._expanded = false;
        this.subsBreakpoints = this.breakpointsService.emitter.subscribe(function (val) { return _this.screenLG = val >= 4; });
        try {
            this.appVersion = document.body.dataset.appVersion || '-1';
        }
        catch (_) { }
    }
    Object.defineProperty(NavbarComponent.prototype, "menuOpened", {
        get: function () { return this._menuOpened; },
        set: function (val) {
            this._menuOpened = val;
            this.recalculateExpanded();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavbarComponent.prototype, "screenLG", {
        get: function () { return this._screenLG; },
        set: function (val) {
            this._screenLG = val;
            this.recalculateExpanded();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavbarComponent.prototype, "expanded", {
        get: function () { return this._expanded; },
        enumerable: true,
        configurable: true
    });
    NavbarComponent.prototype.ngOnInit = function () {
        this.userDetails$ = this.authService.getUser();
    };
    NavbarComponent.prototype.ngOnDestroy = function () {
        safeUnsubscribe(this.subsBreakpoints);
    };
    NavbarComponent.prototype.logout = function () {
        this.menuOpened = this.userMenuOpened = false;
        this.authService.logout();
    };
    NavbarComponent.prototype.onClickHome = function () {
        this.menuOpened = false;
    };
    NavbarComponent.prototype.onExpandedChange = function (val) {
        if (!this.screenLG) {
            this.menuOpened = val;
        }
    };
    NavbarComponent.prototype.onBlur = function () {
        if (this.screenLG && !this.mouseOverUserMenu) {
            this.menuOpened = this.userMenuOpened = false;
        }
    };
    NavbarComponent.prototype.recalculateExpanded = function () {
        this._expanded = this.menuOpened || this.screenLG;
    };
    return NavbarComponent;
}());
export { NavbarComponent };
