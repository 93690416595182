import { CONTRACT } from './contract.graphql';

export const RESET_AND_UPDATE_CONTRACT_ASSOCIATION = {
    __params: {
        $contractId: 'Int!',
        $gymIds: '[Int!]!',
     },
    resetAndUpdateContractAssociation: {
        __params: {
            contractId: '$contractId',
            gymIds: '$gymIds',
        },
        ...CONTRACT,
    },
};

export type ResetAndUpdateContractAssociation = typeof RESET_AND_UPDATE_CONTRACT_ASSOCIATION;
