import { from, of } from 'rxjs';
import { Router } from '@angular/router';
import { ClientService } from '@services';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/client.service";
import * as i2 from "@angular/router";
var ClientResolver = (function () {
    function ClientResolver(clientService, router) {
        this.clientService = clientService;
        this.router = router;
    }
    ClientResolver.prototype.resolve = function (route) {
        var _this = this;
        var clientId = parseInt(route.params.clientId, 10);
        return this.clientService
            .client(clientId)
            .pipe(mergeMap(function (val) { return val ? of(val) : _this.clientNotFound(); }), catchError(function () { return _this.clientNotFound(); }));
    };
    ClientResolver.prototype.clientNotFound = function () {
        var promise = this.router.navigate(['/client'], { queryParams: { notfound: true } });
        return from(promise).pipe(map(function () { return null; }));
    };
    ClientResolver.ngInjectableDef = i0.defineInjectable({ factory: function ClientResolver_Factory() { return new ClientResolver(i0.inject(i1.ClientService), i0.inject(i2.Router)); }, token: ClientResolver, providedIn: "root" });
    return ClientResolver;
}());
export { ClientResolver };
