import { AlertMessage } from '@models';
import { onError } from 'apollo-link-error';
import { AlertMessageService, AuthService, BlockUiService } from '@services';
import { ApolloLink } from 'apollo-link';
import { Location } from '@angular/common';

export function linkError(
    authService: AuthService,
    alertMessageService: AlertMessageService,
    blockUiService: BlockUiService,
    location: Location,
): ApolloLink {
  return onError(apolloError => {
    // TODO: como tratar error.networkError?
    if (apolloError.networkError) {
      blockUiService.network_error();
    }
    if (apolloError.graphQLErrors) {
      apolloError.graphQLErrors.forEach(e => console.log(e));
      const shouldLogout = apolloError.graphQLErrors.some(e => e.message['statusCode'] === 401);

      if (shouldLogout) {
        apolloError.response.errors = apolloError.graphQLErrors.filter(e => e.message['statusCode'] === 401);
        alertMessageService.showFlexible(
          apolloError.response.errors.map(e => AlertMessage.error(e.message['message'] || e.message['error'])),
        );
        authService.logout(location.path(true));
      }
    }

  });
}
