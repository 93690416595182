import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { toGqlMutation } from '@utils/json-util';
import { map } from 'rxjs/operators';
import { ACTIVATE_PASS_MUTATION, ActivatedPass, ActivatePassMutation } from '@graphql';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class ActivationPassService {
  constructor(
    private readonly apollo: Apollo,
  ) { }

  activatePass(purchaseId: string, date?: Date, timezone?: string): Observable<ActivatedPass> {
    timezone = timezone || moment.tz.guess();
    date = date || new Date();

    return this.apollo.mutate<ActivatePassMutation>({
      mutation: toGqlMutation('ActivatePass', ACTIVATE_PASS_MUTATION),
      variables: { purchaseId, dateTimeUtc: date.toISOString(), timezone },
      fetchPolicy: 'no-cache',
    }).pipe(
      map(res => res.data.activatePass),
    );
  }
}
