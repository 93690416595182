import * as tslib_1 from "tslib";
import { AlertMessage } from '@models';
import { AlertMessageService, AuthService } from '@services';
import { AbstractInterceptor } from './abstract.interceptor';
import { Location } from '@angular/common';
var AuthInterceptor = (function (_super) {
    tslib_1.__extends(AuthInterceptor, _super);
    function AuthInterceptor(authService, alertMessageService, location) {
        var _this = _super.call(this) || this;
        _this.authService = authService;
        _this.alertMessageService = alertMessageService;
        _this.location = location;
        return _this;
    }
    AuthInterceptor.prototype.going = function (req) {
        var token = this.authService.getAuthToken();
        if (token) {
            req = req.clone({
                setHeaders: {
                    Authorization: "Bearer " + token,
                },
            });
        }
        return req;
    };
    AuthInterceptor.prototype.returning = function (res) {
        if (res.status === 401) {
            this.alertMessageService.showAfterNavigationEnd(AlertMessage.error('Houve um erro de autenticação. Por favor, faça login novamente.'));
            this.authService.logout(this.location.path(true));
        }
        else if (res.status === 403) {
            this.alertMessageService.showFlexible(AlertMessage.error('Parece que você não tem permissão para fazer isso...'));
        }
        return res;
    };
    return AuthInterceptor;
}(AbstractInterceptor));
export { AuthInterceptor };
