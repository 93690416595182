import * as i0 from "./network-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/modal";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "@angular/common";
import * as i5 from "./network-error.component";
var styles_NetworkErrorComponent = [i0.styles];
var RenderType_NetworkErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NetworkErrorComponent, data: {} });
export { RenderType_NetworkErrorComponent as RenderType_NetworkErrorComponent };
function View_NetworkErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "modal-dialog modal-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "modal-header network-error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Erro de conex\u00E3o"], ["src", "assets/common/ic-empty-cloud.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Esta p\u00E1gina n\u00E3o existe ou encontra-se indispon\u00EDvel. Verifique sua conex\u00E3o ou tente novamente mais tarde. "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "modal-footer row no-gutters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-12 col-sm-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-primary btn-block"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"]))], null, null); }
export function View_NetworkErrorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { modal: 0 }), (_l()(), i1.ɵeld(1, 16777216, null, null, 4, "div", [["bsModal", ""], ["class", "modal fade"], ["role", "dialog"], ["tabindex", "-1"]], null, [[null, "onShow"], [null, "onHide"], [null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } if (("onShow" === en)) {
        var pd_2 = ((_co.isOpen = true) !== false);
        ad = (pd_2 && ad);
    } if (("onHide" === en)) {
        var pd_3 = ((_co.isOpen = false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, [[1, 4]], 0, i2.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i3.ComponentLoaderFactory], { config: [0, "config"] }, { onShow: "onShow", onHide: "onHide" }), i1.ɵpod(3, { keyboard: 0, class: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NetworkErrorComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, "modal-md"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isOpen; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_NetworkErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-network-error", [], null, null, null, View_NetworkErrorComponent_0, RenderType_NetworkErrorComponent)), i1.ɵdid(1, 49152, null, 0, i5.NetworkErrorComponent, [], null, null)], null, null); }
var NetworkErrorComponentNgFactory = i1.ɵccf("app-network-error", i5.NetworkErrorComponent, View_NetworkErrorComponent_Host_0, {}, {}, []);
export { NetworkErrorComponentNgFactory as NetworkErrorComponentNgFactory };
