import { Breadcrumbs } from '@models';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var BreadcrumbService = (function () {
    function BreadcrumbService(router) {
        this.router = router;
        this.queryParamsMap = {};
    }
    BreadcrumbService.prototype.storeQueryParams = function (queryParams) {
        var url = this.router.url.split('?')[0];
        this.queryParamsMap[url] = queryParams;
    };
    BreadcrumbService.prototype.getPreviousState = function (activatedRoute) {
        var currentBc = this.getCurrentBreadcrumb(activatedRoute);
        var allRoutes = activatedRoute.snapshot.pathFromRoot;
        var i;
        for (i = allRoutes.length - 1; i >= 0; i--) {
            var route = allRoutes[i];
            var breadcrumb = route.data.breadcrumb;
            if (breadcrumb && breadcrumb !== currentBc) {
                break;
            }
        }
        var routesForUrl = allRoutes
            .slice(0, i + 1)
            .map(function (r) { return r.url[0]; })
            .filter(function (routeUrl) { return routeUrl; });
        var bc = allRoutes[i].data.breadcrumb || Breadcrumbs.HOME;
        var url = '/' + routesForUrl.join('/');
        var queryParams = this.queryParamsMap[url];
        return { url: url, queryParams: queryParams, bc: bc };
    };
    BreadcrumbService.prototype.getCurrentBreadcrumb = function (route) {
        return route.snapshot.data.breadcrumb;
    };
    BreadcrumbService.ngInjectableDef = i0.defineInjectable({ factory: function BreadcrumbService_Factory() { return new BreadcrumbService(i0.inject(i1.Router)); }, token: BreadcrumbService, providedIn: "root" });
    return BreadcrumbService;
}());
export { BreadcrumbService };
