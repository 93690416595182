import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BlockUiService } from '@services';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BlockUIAction } from '@models';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { NetworkErrorComponent } from '@components/network-error/network-error.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @BlockUI() blockUI: NgBlockUI;
  modalRef: BsModalRef;
  @ViewChild('network_error') networkErrorComponent: NetworkErrorComponent;

  constructor(
    private readonly blockUiService: BlockUiService,
    readonly _breadcrumbService: BreadcrumbService,
    private readonly modalService: BsModalService,
    private readonly localeService: BsLocaleService,
  ) {
    this.localeService.use('ptbr');

    this.blockUiService.emitStatus.subscribe(status => {
      switch (status.action) {
        case BlockUIAction.START:
          this.blockUI.start();
          break;

        case BlockUIAction.START_CUSTOM_MESSAGE:
          this.blockUI.start(status.message);
          break;

        case BlockUIAction.STOP:
          this.blockUI.stop();
          break;

        case BlockUIAction.NETWORK_ERROR:
          this.blockUI.stop();
          this.networkErrorComponent.openModal();
          break;
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

}
