import { toGqlQuery } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { CLIENT_EMAIL_QUERY, CLIENT_EXTENDED_QUERY, CLIENT_PHOTO_QUERY, CLIENT_QUERY, SEARCH_CLIENTS_QUERY, } from '@graphql';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as moment from 'moment-timezone';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var ClientService = (function () {
    function ClientService(apollo) {
        this.apollo = apollo;
    }
    ClientService.prototype.searchClients = function (searchWord, entriesByPage, ignoreIds, timezone) {
        if (ignoreIds === void 0) { ignoreIds = []; }
        timezone = timezone || moment.tz.guess();
        return this.apollo.query({
            query: toGqlQuery('SearchClientsQuery', SEARCH_CLIENTS_QUERY),
            variables: { searchWord: searchWord, entriesByPage: entriesByPage, ignoreIds: ignoreIds, timezone: timezone },
            fetchPolicy: 'no-cache',
        }).pipe(map(function (res) { return res.data.searchClient; }));
    };
    ClientService.prototype.client = function (id, timezone) {
        timezone = timezone || moment.tz.guess();
        if (!id) {
            return of(null);
        }
        return this.apollo.query({
            query: toGqlQuery('ClientQuery', CLIENT_QUERY),
            variables: { id: id, timezone: timezone },
            fetchPolicy: 'no-cache',
        }).pipe(map(function (res) { return res.data.client; }));
    };
    ClientService.prototype.getEmail = function (clientId) {
        return this.apollo.query({
            query: toGqlQuery('ClientEmailQuery', CLIENT_EMAIL_QUERY),
            variables: { id: clientId },
            fetchPolicy: 'cache-first',
            context: {
                skipBatching: true,
                noBlockUi: true,
            },
        }).pipe(map(function (res) { return res.data.client.email; }));
    };
    ClientService.prototype.getPhoto = function (clientId) {
        return this.apollo.query({
            query: toGqlQuery('ClientPhotoQuery', CLIENT_PHOTO_QUERY),
            variables: { id: clientId },
            fetchPolicy: 'cache-first',
            context: {
                skipBatching: true,
                noBlockUi: true,
            },
        }).pipe(map(function (res) { return res.data.client.urlPhoto; }));
    };
    ClientService.prototype.getExtendedInformation = function (clientId) {
        return this.apollo.query({
            query: toGqlQuery('ClientExtendedQuery', CLIENT_EXTENDED_QUERY),
            variables: { id: clientId },
        }).pipe(map(function (res) { return res.data.client.extraInformation; }));
    };
    ClientService.ngInjectableDef = i0.defineInjectable({ factory: function ClientService_Factory() { return new ClientService(i0.inject(i1.Apollo)); }, token: ClientService, providedIn: "root" });
    return ClientService;
}());
export { ClientService };
