import { BehaviorSubject, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { BlockUIAction, BlockUIStatus } from '@models';
import { debounce } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BlockUiService {
  private counter = 0;
  private readonly _emitStatus = new BehaviorSubject<BlockUIStatus>({ action: BlockUIAction.NOOP });
  readonly emitStatus = this._emitStatus.pipe(
    debounce( status => {
      if (status.action === BlockUIAction.START || status.action === BlockUIAction.START_CUSTOM_MESSAGE) {
        return timer(0);
      } else {
        return timer(200);
      }
    }),
  );

  increment(message?: string) {
    this.counter += 1;
    let status: BlockUIStatus = null;
    if (message) {
      status = { action: BlockUIAction.START_CUSTOM_MESSAGE, message };
    } else {
      status = { action: BlockUIAction.START };
    }
    this._emitStatus.next(status);
  }

  decrement() {
    this.counter -= 1;
    if (this.counter < 0) { this.counter = 0; }

    if (this.counter === 0) {
      this._emitStatus.next({ action: BlockUIAction.STOP });
    }
  }

  clear() {
    this.counter = 0;
    this._emitStatus.next({ action: BlockUIAction.STOP });
  }

  network_error() {
    this.counter = 0;
    this._emitStatus.next({ action: BlockUIAction.NETWORK_ERROR });
  }
}
