import * as i0 from "./base-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../navbar/navbar.component.ngfactory";
import * as i3 from "../navbar/navbar.component";
import * as i4 from "../../services/auth.service";
import * as i5 from "../../services/breakpoints.service";
import * as i6 from "@angular/router";
import * as i7 from "./base-layout.component";
var styles_BaseLayoutComponent = [i0.styles];
var RenderType_BaseLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BaseLayoutComponent, data: {} });
export { RenderType_BaseLayoutComponent as RenderType_BaseLayoutComponent };
export function View_BaseLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 245760, null, 0, i3.NavbarComponent, [i4.AuthService, i5.BreakpointsService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "main container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); }, null); }
export function View_BaseLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-base-layout", [], null, null, null, View_BaseLayoutComponent_0, RenderType_BaseLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i7.BaseLayoutComponent, [], null, null)], null, null); }
var BaseLayoutComponentNgFactory = i1.ɵccf("app-base-layout", i7.BaseLayoutComponent, View_BaseLayoutComponent_Host_0, {}, {}, []);
export { BaseLayoutComponentNgFactory as BaseLayoutComponentNgFactory };
