export interface BreadcrumbData {
  label: string;
  icon: string;
}

export interface BreadcrumbState {
  url: string;
  queryParams?: any;
  bc: BreadcrumbData;
}

export const Breadcrumbs = {
  HOME: {
    label: 'BT PASS',
    icon:  'fas fa-home',
  },
  CLIENT_SEARCH: {
    label: 'Cliente',
    icon:  'fas fa-home',
  },
  CLIENT_DETAILS: {
    label: 'Detalhes',
    icon: 'fas fa-file-contract',
  },
  SETTING_SEARCH: {
    label: 'Configurações',
    icon: 'fas fa-cog',
  },
  CONTRACT_NO_GYM: {
    label: 'Detalhes - Unidades sem contratos associados',
    icon: 'fas fa-cog',
  },
  CONTRACTS: {
    label: 'Contratos',
    icon: 'fas fa-file-contract',
  },
  CANCEL_CONTRACT: {
    label: 'Cancelamento',
    icon: 'fas fa-file-contract',
  },
  CONTRACT_HISTORY: {
    label: 'Histórico de contratos',
    icon: 'fas fa-file-contract',
  },
};
