import * as tslib_1 from "tslib";
import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injector } from '@angular/core';
import { environment } from '@env';
import { AuthService } from '@services';
Sentry.init(environment.sentry);
var SentryErrorHandler = (function (_super) {
    tslib_1.__extends(SentryErrorHandler, _super);
    function SentryErrorHandler(injector) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        return _this;
    }
    SentryErrorHandler.prototype.getAuthService = function () {
        return this.injector.get(AuthService);
    };
    SentryErrorHandler.prototype.handleError = function (error) {
        var eid = Sentry.captureException(error.originalError || error);
        var email;
        var name;
        var authService = this.getAuthService();
        if (authService.isLoggedIn() && authService.userDetailsSubject.getValue()) {
            name = authService.userDetailsSubject.getValue().displayName;
            email = authService.userDetailsSubject.getValue().username + '@no-reply.bodytech.com.br';
        }
        Sentry.showReportDialog({
            eventId: eid,
            user: {
                name: name,
                email: email,
            },
            lang: 'pt-br',
            title: 'Hum... parece que tivemos um problema.',
            subtitle: 'O time de desenvolvimento já foi notificado.',
            subtitle2: 'Se você quiser ajudar, descreva o que você estava tentando fazer antes de ver esta tela.',
            labelName: 'Nome',
            labelEmail: 'Email',
            labelComments: 'O que aconteceu?',
            labelClose: 'Fechar',
            labelSubmit: 'Enviar relatório de erro',
            errorGeneric: 'Um erro desconhecido aconteceu no envio do relatório de erro. Por favor, tente novamente.',
            errorFormEntry: 'Alguns campos estão inválidos. Por favor, corrija os erros e tente novamente.',
            successMessage: 'O relatório foi enviado com sucesso. Muito obrigado!!!',
        });
        console.error(error);
    };
    return SentryErrorHandler;
}(ErrorHandler));
export { SentryErrorHandler };
