import { types } from 'typed-graphqlify';

export const ADD_CONTRACT_ASSOCIATION_MUTATION = {
  __params: { $contractId: 'Int!', $gymId: 'Int!' },
  addContractAssociation: {
    __params: { contractId: '$contractId', gymId: '$gymId' },
    id: types.number,
  },
};

export type AddContractAssociationMutation = typeof ADD_CONTRACT_ASSOCIATION_MUTATION;

export interface AddContractAssociationParams {
  contractId: number;
  gymId: number;
}
