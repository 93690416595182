import { environment } from '@env';

const genericEndpointInterpolation = (baseurl: string, path: string, params: Array<string | number> = []): string => {
  const interpoled = params.reduce<string>((old, curr, i) => old.split(`{{${i}}}`).join(curr.toString()), path);
  return baseurl + interpoled;
};

export const enum LegacyEndpoints { // MUST start with a slash (/)
  Root = '/',
  AppToken = '/Aut/Canal/Token',
  Login = '/Autenticacao/Usuario/',
  Logout = '/Autenticacao/Usuario/logoff/',
  ResetUserAccessToken = '/Autenticacao/Usuario/reset',
}

export const legacy = (path: LegacyEndpoints, ...params: Array<string | number>): string => {
  return genericEndpointInterpolation(environment.legacy.baseUrl, path, params);
};

export const enum BackEndpoints { // MUST start with a slash (/)
  Root = '/',
  AppToken = '/auth',
  Graphql = '/graphql',
}

export const back = (path: BackEndpoints, ...params: Array<string | number>): string => {
  return genericEndpointInterpolation(environment.back.baseUrl, path, params);
};
