import * as tslib_1 from "tslib";
import { EMPTY } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
var ShouldAbort = (function (_super) {
    tslib_1.__extends(ShouldAbort, _super);
    function ShouldAbort() {
        return _super.call(this) || this;
    }
    return ShouldAbort;
}(Error));
export { ShouldAbort };
var AbstractInterceptor = (function () {
    function AbstractInterceptor() {
    }
    AbstractInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        try {
            return next.handle(this.going(req)).pipe(filter(function (event) { return event.type === HttpEventType.Response; }), map(function (res) {
                if (res.type === HttpEventType.Response) {
                    return _this.returning(res, req);
                }
                else {
                    return res;
                }
            }));
        }
        catch (e) {
            if (e instanceof ShouldAbort) {
                return EMPTY;
            }
            throw e;
        }
    };
    AbstractInterceptor.prototype.going = function (req) { return req; };
    AbstractInterceptor.prototype.returning = function (res, req) { return res; };
    return AbstractInterceptor;
}());
export { AbstractInterceptor };
