import { NgModule } from '@angular/core';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';

import { Apollo, ApolloModule } from 'apollo-angular';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { HttpBatchLink, HttpBatchLinkModule } from 'apollo-angular-link-http-batch';
import { AuthService, BlockUiService } from '@services';

import { back, BackEndpoints } from '@utils/app-endpoints';
import { AlertMessageService } from '@services';
import { linkError } from '@utils/apollo-config/error.middleware';
import { setContext } from 'apollo-link-context';
import { NO_BLOCK_UI_HTTP_HEADER } from '@models';
import { DefaultOptions } from 'apollo-client/ApolloClient';
import { Location } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    HttpBatchLinkModule,
    ApolloModule,
  ],
})
export class ApolloConfigModule {

  constructor(
    public readonly apollo: Apollo,
    public readonly httpLink: HttpBatchLink,
    public readonly authService: AuthService,
    public readonly alertMessageService: AlertMessageService,
    public readonly blockUiService: BlockUiService,
    public readonly location: Location,
  ) {

    const http = this.httpLink.create({
      uri: back(BackEndpoints.Graphql),
    });

    const noBlockUiLink = setContext((_, ctx) => {
      if (ctx.noBlockUi) {
        const headers = (ctx.headers || new HttpHeaders());

        return {
          headers: headers.set(NO_BLOCK_UI_HTTP_HEADER, 'NO-BLOCK-UI'),
        };
      }
    });

    const defaultOptions: DefaultOptions = {
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    };

    apollo.create({
      link: ApolloLink.from([
        linkError(
          this.authService,
          this.alertMessageService,
          this.blockUiService,
          this.location,
        ),
        noBlockUiLink,
        http,
      ]),
      cache: new InMemoryCache(),
      defaultOptions,
    });
  }

}
