import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkErrorComponent } from './network-error.component';
import { ModalModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [
    NetworkErrorComponent,
  ],
  imports: [
    CommonModule,
    ModalModule,
  ],
  exports: [
    NetworkErrorComponent,
  ],
})
export class NetworkErrorModule { }
