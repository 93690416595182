
export function insertIntoIndex<T>(array: T[], index: number, value: T) {
  array.splice(index, 0, value);
}

export function removeItem<T>(array: T[], item: T) {
  if (array.includes(item)) {
    array.splice(array.indexOf(item), 1);
  }
}

export function removeRepeatedItemsFn<T>(value: T, index: number, array: T[]): boolean {
  return array.indexOf(value) === index;
}

export function removeRepeatedItems<T>(array: T[]): T[] {
  return array.filter(removeRepeatedItemsFn);
}

export function findMax<T>(array: T[], predicate: (item: T) => any = i => i) {
  if (!array || !array.length) {
    return undefined;
  }

  let maxItem = array[0];
  let maxValue = predicate(array[0]);

  for (const item of array) {
    const value = predicate(item);

    if (value > maxValue) {
      maxValue = value;
      maxItem = item;
    }
  }

  return maxItem;
}

export function removeRepeatedInObjectArray<T>(array: T[], key: string): T[] {
  const uniqueArr = array.map(e => e[key])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => array[e]).map(e => array[e]);

  return uniqueArr;
}
