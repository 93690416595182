<div class="login-container">
  <div class="card">
    <img class="logo" src="assets/bt/logo/portrait.svg">
    <div class="card-body">
      <h1 class="card-title text-center title-bold">BT Pass</h1>
      <form [formGroup]="loginForm" (ngSubmit)="submitLogin()" autocomplete="off">
        <app-form-error [autoRetrieve]="true"></app-form-error>
        <formly-form [fields]="loginFields" [model]="credentials" [form]="loginForm"></formly-form>
        <div class="pt-3">
          <button type="submit" [disabled]="loginForm.invalid" id="submit_btn" class="btn btn-primary btn-block">Entrar</button>
        </div>
      </form>
    </div>
  </div>
</div>
