import * as tslib_1 from "tslib";
var _a;
import { alias, types } from 'typed-graphqlify';
export var PASS_ACTIVATION = {
    id: types.number,
    sortNumber: types.number,
    dateTimeUtc: types.string,
    timezone: types.string,
};
export var PURCHASE = (_a = {
        id: types.string,
        sgaShoppingCartId: types.string,
        expirationDate: types.string,
        contractDate: types.string,
        usedPasses: types.number,
        totalPasses: types.number,
        activations: [PASS_ACTIVATION],
        planValue: types.string,
        userCancelPermission: {
            canCancel: types.boolean,
            reason: types.string,
        },
        cancelationData: {
            unitaryValue: types.number,
            residualValue: types.number,
            fineValue: types.number,
            reverseValue: types.number,
        },
        isCanceled: types.boolean,
        cancelReason: types.string,
        contractCancelDate: types.string
    },
    _a[alias('status', 'status(timezone: $timezone)')] = types.string,
    _a[alias('isActive', 'isActive(timezone: $timezone)')] = types.boolean,
    _a.plan = {
        name: types.string,
        planType: types.string,
        amountOfPasses: types.number,
    },
    _a.gymUnity = {
        id: types.number,
        name: types.string,
    },
    _a.contract = {
        id: types.number,
        path: types.string,
        name: types.string,
    },
    _a);
export var CLIENT_DETAILS = {
    enrollmentId: types.number,
    fullName: types.string,
    documentNumber: types.string,
    purchases: [PURCHASE],
};
export var CLIENT_QUERY = {
    __params: { $id: 'Int!', $timezone: 'String!' },
    client: tslib_1.__assign({ __params: { id: '$id' } }, CLIENT_DETAILS),
};
