import { PlanService } from '@services';
import * as i0 from "@angular/core";
import * as i1 from "../services/plan.service";
var PlanResolver = (function () {
    function PlanResolver(planService) {
        this.planService = planService;
    }
    PlanResolver.prototype.resolve = function () {
        return this.planService.list();
    };
    PlanResolver.ngInjectableDef = i0.defineInjectable({ factory: function PlanResolver_Factory() { return new PlanResolver(i0.inject(i1.PlanService)); }, token: PlanResolver, providedIn: "root" });
    return PlanResolver;
}());
export { PlanResolver };
