import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { GymUnityService } from '@services';
import { GymUnity } from '@graphql';

@Injectable({
  providedIn: 'root',
})
export class GymsResolver implements Resolve<GymUnity[]> {
  constructor(
    private readonly gymUnityService: GymUnityService,
  ) {}

  resolve() {
    return this.gymUnityService.getAllGym();
  }
}
