import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Resolve,
} from '@angular/router';

import { AuthService } from '@services';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad, Resolve<Observable<any>> {

  constructor(
    private readonly authService: AuthService,
    private readonly location: Location,
  ) {}

  canActivateChild = this.canActivate;

  canActivate(): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      this.authService.logout(this.location.path(true));
    }
  }

  canLoad(): boolean {
    return this.authService.isLoggedIn();
  }

  resolve() {
    return this.authService.getUserDetails();
  }
}
