import { types } from 'typed-graphqlify';

export const CLIENT_EXTENDED = {
  registrationDate: types.string,
  shortenedName: types.string,

  rg: types.string,
  rgExpeditor: types.string,
  cpf: types.string,
  birthDate: types.string,
  motherName: types.string,
  fatherName: types.string,
  guardianName: types.string,
  guardianCpf: types.string,

  naturality: types.string,
  nationality: types.string,
  isForeigner: types.boolean,
  foreignDocument: types.string,
  civilStatus: types.string,
  gender: types.string,
  profession: types.string,

  address: types.string,
  addressNumber: types.string,
  addressComplemento: types.string,
  addressCep: types.string,
  addressBairro: types.string,
  addressCity: types.string,
  addressState: types.string,

  mainEmail: types.string,
  mainPhone: types.string,
  otherPhone: types.string,
  preferentialContactName: types.string,
  preferentialContactPhone: types.string,
  otherContactName: types.string,
  otherContactPhone: types.string,
};

export type ClientExtended = typeof CLIENT_EXTENDED;

export const CLIENT_EXTENDED_QUERY = {
  __params: { $id: 'Int!' },
  client: {
    __params: { id: '$id' },
    extraInformation: CLIENT_EXTENDED,
  },
};

export type ClientExtendedQuery = typeof CLIENT_EXTENDED_QUERY;
