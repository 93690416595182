import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AlertMessage } from '@models';
// import { oldCanaisErrorMessages } from '@utils/app-errors';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

type RelevantRouterEvents = NavigationStart | NavigationEnd;

@Injectable({ providedIn: 'root' })
export class AlertMessageService {
  public readonly emitter: Subject<AlertMessage[]> = new Subject();
  public readonly cleanMessages: Subject<void> = new Subject();
  private stackedMessages: AlertMessage[] = [];
  private lastRouterEvent: RelevantRouterEvents;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly router: Router,
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd))
      .subscribe((event: RelevantRouterEvents) => this.lastRouterEvent = event);
  }

  static genericError(reason: string) {
    const arr = reason.split(' ');
    return {
      code: arr.splice(0, 1).join(' '),
      message: arr.join(' '),
    };
  }

  public consume(): AlertMessage[] {
    const temp = this.stackedMessages;
    this.stackedMessages = [];
    return temp;
  }

  public showAfterNavigationEnd(msg: AlertMessage[] | AlertMessage) {
    if (!Array.isArray(msg)) { msg = [msg]; }
    this.stackedMessages.push(...msg);
    if (isPlatformBrowser(this.platformId)) { window.scrollTo(0, 0); }
  }

  public showNow(msg: AlertMessage[] | AlertMessage) {
    if (!Array.isArray(msg)) { msg = [msg]; }
    this.emitter.next(msg);
  }

  public showFlexible(msg: AlertMessage[] | AlertMessage) {
    if (this.lastRouterEvent instanceof NavigationStart) {
      this.showAfterNavigationEnd(msg);
    } else if (this.lastRouterEvent instanceof NavigationEnd) {
      this.showNow(msg);
    }
  }

  public clear() {
    this.cleanMessages.next();
  }

}
