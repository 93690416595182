import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ClientService } from '@services';
import { ClientDetails } from '@graphql';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientResolver implements Resolve<Observable<ClientDetails | boolean>> {
  constructor(
    private readonly clientService: ClientService,
    private readonly router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const clientId = parseInt(route.params.clientId, 10);
    return this.clientService
      .client(clientId)
      .pipe(
        mergeMap(val => val ? of(val) : this.clientNotFound()),
        catchError(() => this.clientNotFound()),
      );
  }

  private clientNotFound() {
    const promise = this.router.navigate(['/client'], { queryParams: { notfound: true }});
    return from(promise).pipe(map(() => null));
  }
}
