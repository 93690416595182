import { EmbeddedViewRef, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@services';
import { map, skip, tap } from 'rxjs/operators';
import { safeUnsubscribe } from '@utils/rxjs-util';
var CanDirective = (function () {
    function CanDirective(_viewContainer, templateRef, authService) {
        var _this = this;
        this._viewContainer = _viewContainer;
        this.templateRef = templateRef;
        this.authService = authService;
        this.permissionsList = [];
        this._thenTemplateRef = null;
        this._elseTemplateRef = null;
        this._thenViewRef = null;
        this._elseViewRef = null;
        this._thenTemplateRef = this.templateRef;
        this.subs = this.authService.userDetailsSubject.pipe(map(function (user) {
            try {
                return user.profile.permissions.map(function (p) { return p.code; });
            }
            catch (_) {
                return [];
            }
        }), tap(function (perms) { return _this.permissionsList = perms; }), skip(1)).subscribe(function () { return _this.update(); });
    }
    Object.defineProperty(CanDirective.prototype, "can", {
        set: function (param) {
            if (Array.isArray(param)) {
                this.param = param;
            }
            else {
                this.param = [param];
            }
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanDirective.prototype, "canThen", {
        set: function (templateRef) {
            this._thenTemplateRef = templateRef;
            this._thenViewRef = null;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CanDirective.prototype, "canElse", {
        set: function (templateRef) {
            this._elseTemplateRef = templateRef;
            this._elseViewRef = null;
            this.update();
        },
        enumerable: true,
        configurable: true
    });
    CanDirective.prototype.update = function () {
        var _this = this;
        var condition = this.param.length ? this.param.some(function (p) { return _this.permissionsList.includes(p); }) : true;
        if (condition) {
            if (!this._thenViewRef) {
                this._viewContainer.clear();
                this._elseViewRef = null;
                if (this._thenTemplateRef) {
                    this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef);
                }
            }
        }
        else {
            if (!this._elseViewRef) {
                this._viewContainer.clear();
                this._thenViewRef = null;
                if (this._elseTemplateRef) {
                    this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef);
                }
            }
        }
    };
    CanDirective.prototype.ngOnDestroy = function () {
        safeUnsubscribe(this.subs);
    };
    return CanDirective;
}());
export { CanDirective };
