import { BlockUiService } from '@services';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { AbstractInterceptor } from './abstract.interceptor';
import { NO_BLOCK_UI_HTTP_HEADER } from '@models';

@Injectable()
export class BlockUiInterceptor extends AbstractInterceptor {

  constructor(
    private readonly blockUiService: BlockUiService,
  ) {
    super();
  }

  going(req: HttpRequest<any>): HttpRequest<any> {
    const noBlockUi = req.headers.get(NO_BLOCK_UI_HTTP_HEADER);

    if (!noBlockUi) {
      this.blockUiService.increment();
    }

    return req.clone( {
      headers: req.headers.delete(NO_BLOCK_UI_HTTP_HEADER),
    });
  }

  returning(res: HttpResponse<any>, req: HttpRequest<any>): HttpResponse<any> {
    const noBlockUi = req.headers.get(NO_BLOCK_UI_HTTP_HEADER);

    if (!noBlockUi) {
      this.blockUiService.decrement();
    }

    return res;
  }
}
