import { types } from 'typed-graphqlify';

const ACTIVATED_PASS = {
  id: types.number,
  sortNumber: types.number,
  dateTimeUtc: types.string,
  timezone: types.string,
  purchase: {
    usedPasses: types.number,
    isActive: types.boolean,
  },
};

export type ActivatedPass = typeof ACTIVATED_PASS;

ACTIVATED_PASS.purchase['isActive(timezone: $timezone)'] = ACTIVATED_PASS.purchase.isActive;
delete ACTIVATED_PASS.purchase.isActive;

export const ACTIVATE_PASS_MUTATION = {
    __params: {$purchaseId: 'String!', $dateTimeUtc: 'String!', $timezone: 'String!'},
    activatePass: {
        __params: {
            purchaseId: '$purchaseId',
            dateTimeUtc: '$dateTimeUtc',
            timezone: '$timezone',
        },
      ...ACTIVATED_PASS,
    },
};

export type ActivatePassMutation = typeof ACTIVATE_PASS_MUTATION;
