export const enum AlertMessageType {
  S = 'S',
  SUCCESS = S,
  E = 'E',
  ERROR = E,
  W = 'W',
  WARNING = W,
  I = 'I',
  INFO = I,
  D = 'D',
  DEBUG = D,
}

export abstract class AlertMessage {
  msg: string;
  type: AlertMessageType;

  static success(message: string) {
    return {
      type: AlertMessageType.S,
      msg: message,
    };
  }

  static error(message: string) {
    return {
      type: AlertMessageType.E,
      msg: message,
    };
  }

  static warning(message: string) {
    return {
      type: AlertMessageType.W,
      msg: message,
    };
  }

  static info(message: string) {
    return {
      type: AlertMessageType.I,
      msg: message,
    };
  }

  static debug(message: string) {
    return {
      type: AlertMessageType.D,
      msg: message,
    };
  }
}
