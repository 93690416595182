import { Directive, EmbeddedViewRef, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@services';
import { map, skip, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { safeUnsubscribe } from '@utils/rxjs-util';

@Directive({
  // tslint:disable-next-line
  selector: '[can]',
})
export class CanDirective implements OnDestroy {
  private subs: Subscription;
  private permissionsList: string[] = [];
  private param: string[];

  private _thenTemplateRef: TemplateRef<any> | null = null;
  private _elseTemplateRef: TemplateRef<any> | null = null;

  private _thenViewRef: EmbeddedViewRef<any> | null = null;
  private _elseViewRef: EmbeddedViewRef<any> | null = null;

  @Input()
  set can(param: string | string[]) {
    if (Array.isArray(param)) {
      this.param = param;
    } else {
      this.param = [param];
    }
    this.update();
  }

  @Input()
  set canThen(templateRef: TemplateRef<any>) {
    this._thenTemplateRef = templateRef;
    this._thenViewRef = null;
    this.update();
  }

  @Input()
  set canElse(templateRef: TemplateRef<any>) {
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null;
    this.update();
  }

  constructor(
    private _viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthService,
  ) {
    this._thenTemplateRef = this.templateRef;
    this.subs = this.authService.userDetailsSubject.pipe(
      map(user => {
        try {
          return user.profile.permissions.map(p => p.code);
        } catch (_) {
          return [];
        }
      }),
      tap(perms => this.permissionsList = perms),
      skip(1),
    ).subscribe(() => this.update());
  }

  private update() {
    const condition = this.param.length ? this.param.some(p => this.permissionsList.includes(p)) : true;

    if (condition) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        this._elseViewRef = null;
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef);
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._viewContainer.clear();
        this._thenViewRef = null;
        if (this._elseTemplateRef) {
          this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef);
        }
      }
    }
  }

  ngOnDestroy() {
    safeUnsubscribe(this.subs);
  }

}
