import { AnimationEvent, style } from '@angular/animations';
import { EventEmitter } from '@angular/core';
var styleCollapsed = style({ height: '0px', visibility: 'hidden', display: 'none' });
var styleExpanded = style({ height: '*', visibility: 'visible' });
var CollapseComponent = (function () {
    function CollapseComponent() {
        this.animation = true;
        this._expanded = false;
        this.afterExpand = new EventEmitter();
        this.afterCollapse = new EventEmitter();
        this.expandedChange = new EventEmitter();
        this.closed = new EventEmitter();
        this.opened = new EventEmitter();
    }
    Object.defineProperty(CollapseComponent.prototype, "expanded", {
        get: function () { return this._expanded; },
        set: function (expanded) {
            expanded = Boolean(expanded);
            if (this._expanded !== expanded) {
                this._expanded = expanded;
                this.expandedChange.emit(expanded);
                if (expanded) {
                    this.opened.emit();
                }
                else {
                    this.closed.emit();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    CollapseComponent.prototype._getExpandedState = function () {
        return (this.expanded ? 'expanded' : 'collapsed') + (this.animation ? '' : '_animationless');
    };
    CollapseComponent.prototype._bodyAnimation = function (event) {
        var classList = event.element.classList;
        var cssClass = 'expanded';
        var phaseName = event.phaseName, toState = event.toState, fromState = event.fromState;
        if (phaseName === 'done' && toState === 'expanded') {
            classList.add(cssClass);
        }
        if (phaseName === 'start' && toState === 'collapsed') {
            classList.remove(cssClass);
        }
        if (phaseName === 'done' && toState === 'expanded' && fromState !== 'void') {
            this.afterExpand.emit();
        }
        if (phaseName === 'done' && toState === 'collapsed' && fromState !== 'void') {
            this.afterCollapse.emit();
        }
    };
    CollapseComponent.prototype.toggle = function () {
        this.expanded = !this._expanded;
    };
    CollapseComponent.prototype.open = function () {
        this.expanded = true;
    };
    CollapseComponent.prototype.close = function () {
        this.expanded = false;
    };
    return CollapseComponent;
}());
export { CollapseComponent };
