import { S3Service } from '@services/s3.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/s3.service";
var S3PolicySettingsResolver = (function () {
    function S3PolicySettingsResolver(s3Service) {
        this.s3Service = s3Service;
    }
    S3PolicySettingsResolver.prototype.resolve = function () {
        return this.s3Service.reloadPolicySettings();
    };
    S3PolicySettingsResolver.ngInjectableDef = i0.defineInjectable({ factory: function S3PolicySettingsResolver_Factory() { return new S3PolicySettingsResolver(i0.inject(i1.S3Service)); }, token: S3PolicySettingsResolver, providedIn: "root" });
    return S3PolicySettingsResolver;
}());
export { S3PolicySettingsResolver };
