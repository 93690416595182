import { types } from 'typed-graphqlify';

const DISASSOCIATED_CONTRACT = {
  message: types.string,
};

export type DisassociatedContract = typeof DISASSOCIATED_CONTRACT;

export const DISASSOCIATE_CONTRACT_MUTATION = {
    __params: {$contractId: 'Int!', $gymId: 'Int!'},
    disassociateContract: {
        __params: {
            contractId: '$contractId',
            gymId: '$gymId'},
        ...DISASSOCIATED_CONTRACT,
    },
};

export type DisassociateContractMutation = typeof DISASSOCIATE_CONTRACT_MUTATION;
