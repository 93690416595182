import * as Sentry from '@sentry/browser';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { environment } from '@env';
import { AuthService } from '@services';

Sentry.init(environment.sentry);

@Injectable()
export class SentryErrorHandler extends ErrorHandler {
  constructor(
    @Inject(Injector) private injector: Injector,
  ) {
    super();
  }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private getAuthService(): AuthService {
    return this.injector.get(AuthService);
  }

  handleError(error: any): void {
    const eid = Sentry.captureException(error.originalError || error);
    let email;
    let name;
    const authService = this.getAuthService();
    if (authService.isLoggedIn() && authService.userDetailsSubject.getValue()) {
      name = authService.userDetailsSubject.getValue().displayName;
      email = authService.userDetailsSubject.getValue().username + '@no-reply.bodytech.com.br';
    }
    Sentry.showReportDialog({
      eventId: eid,
      user: {
        name,
        email,
      },
      lang: 'pt-br',
      title: 'Hum... parece que tivemos um problema.',
      subtitle: 'O time de desenvolvimento já foi notificado.',
      subtitle2: 'Se você quiser ajudar, descreva o que você estava tentando fazer antes de ver esta tela.',
      labelName: 'Nome',
      labelEmail: 'Email',
      labelComments: 'O que aconteceu?',
      labelClose: 'Fechar',
      labelSubmit: 'Enviar relatório de erro',
      errorGeneric: 'Um erro desconhecido aconteceu no envio do relatório de erro. Por favor, tente novamente.',
      errorFormEntry: 'Alguns campos estão inválidos. Por favor, corrija os erros e tente novamente.',
      successMessage: 'O relatório foi enviado com sucesso. Muito obrigado!!!',
    });
    console.error(error);
  }
}
