import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@views/login/login.component';
import { AuthGuard } from '@guards';
import { NotFoundComponent } from '@views/not-found/not-found.component';
import { BaseLayoutComponent } from '@components/base-layout/base-layout.component';
import { UnauthorizedComponent } from '@views/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/client',
        pathMatch: 'full',
      },
      {
        path: 'client',
        loadChildren: '@views/client/client.module#ClientModule',
      },
      {
        path: 'settings',
        loadChildren: '@views/settings/settings.module#SettingsModule',
      },
      {
        path: 'reports',
        loadChildren: '@views/reports/reports.module#ReportsModule',
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent,
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
