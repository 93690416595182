import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { safeUnsubscribe } from '@utils/rxjs-util';
import { removeItem as ArrayUtil_removeItem, removeRepeatedInObjectArray } from '@utils/array-util';
import { AlertMessage } from '@models';
import { AlertMessageService } from '@services';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ height: 'unset' })),
      transition(':enter', [
        style({ height: '0' }),
        animate(250),
      ]),
      transition(':leave', [
        animate(250, style({ height: '0' })),
      ]),
    ]),
  ],
})
export class FormErrorComponent implements OnInit, OnDestroy {
  @Input() public messages: AlertMessage[];
  @Input() public autoRetrieve = false;

  private subRouter: Subscription;
  private subEmitter: Subscription;
  private subClear: Subscription;

  constructor(
    private readonly router: Router,
    private readonly alertMessageService: AlertMessageService,
  ) { }

  ngOnInit() {
    if (typeof this.messages === 'undefined' || this.messages == null) {
      this.messages = [];
    }
    if (this.autoRetrieve) {
      this.messages.push(...this.alertMessageService.consume());

      this.subRouter = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd
          || event instanceof NavigationCancel
          || event instanceof NavigationError) {
          this.alertMessageService.clear();
          this.messages = removeRepeatedInObjectArray(this.messages.concat(this.alertMessageService.consume()), 'msg');
        }
      });

      this.subEmitter = this.alertMessageService.emitter.subscribe(messages => {
        this.messages = removeRepeatedInObjectArray(this.messages.concat(messages), 'msg');
      });
      this.subClear = this.alertMessageService.cleanMessages.subscribe(() => {
        this.messages = [];
      });

    }
  }

  ngOnDestroy() {
    safeUnsubscribe(this.subRouter);
    safeUnsubscribe(this.subEmitter);
    safeUnsubscribe(this.subClear);
  }

  close(msg: AlertMessage) {
    ArrayUtil_removeItem(this.messages, msg);
  }
}
