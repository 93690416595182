import * as Sentry from '@sentry/browser';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { AUTHENTICATE_USER_MUTATION, USER_DETAILS_QUERY, } from '@graphql';
import { Router } from '@angular/router';
import { copyOf } from '@utils/rxjs-util';
import { toGqlMutation, toGqlQuery } from '@utils/json-util';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "@angular/router";
var AuthService = (function () {
    function AuthService(apollo, router) {
        this.apollo = apollo;
        this.router = router;
        this.userDetailsSubject = new BehaviorSubject(null);
        this.userDetailsSubject.subscribe(function (userDetails) {
            if (userDetails) {
                Sentry.configureScope(function (scope) {
                    scope.setUser({
                        id: userDetails.id.toString(10),
                        username: userDetails.username,
                        accessProfileId: userDetails.accessProfileId,
                        displayName: userDetails.displayName,
                    });
                });
            }
            else {
                Sentry.configureScope(function (scope) {
                    scope.setUser({});
                });
            }
        });
    }
    AuthService.prototype.isLoggedIn = function () {
        return localStorage.getItem("bt-auth-token") != null;
    };
    AuthService.prototype.setUserToken = function (token) {
        localStorage.setItem("bt-auth-token", token);
    };
    AuthService.prototype.clearUserToken = function () {
        localStorage.removeItem("bt-auth-token");
        this.userDetailsSubject.next(null);
    };
    AuthService.prototype.logout = function (next) {
        if (this.isLoggedIn()) {
            this.clearUserToken();
        }
        var navigateExtras = {
            queryParams: { next: next },
        };
        if (this.router.url !== '/login') {
            this.router.navigate(['/login'], next ? navigateExtras : undefined);
        }
    };
    AuthService.prototype.login = function (credentials) {
        var _this = this;
        return this.apollo.mutate({
            mutation: toGqlMutation('AuthenticateUserMutation', AUTHENTICATE_USER_MUTATION),
            variables: credentials,
        }).pipe(map(function (res) { return res.data.login; }), tap(function (res) {
            if (res && res.token) {
                _this.setUserToken(res.token);
                _this.getUser().subscribe();
            }
        }), catchError(function (err) {
            _this.clearUserToken();
            return throwError(err.graphQLErrors.map(function (e) { return e.message; }));
        }));
    };
    AuthService.prototype.getUserDetails = function () {
        var _this = this;
        return this.apollo.query({
            query: toGqlQuery('UserDetailsQuery', USER_DETAILS_QUERY),
            fetchPolicy: 'no-cache',
        }).pipe(map(function (res) { return res.data.userDetails; }), tap(function (val) { return _this.userDetailsSubject.next(val); }), switchMap(copyOf));
    };
    AuthService.prototype.getUser = function () {
        if (this.userDetailsSubject.value) {
            return copyOf(this.userDetailsSubject.value);
        }
        else {
            return this.getUserDetails();
        }
    };
    AuthService.prototype.getAuthToken = function () {
        return localStorage.getItem("bt-auth-token");
    };
    AuthService.prototype.userCanActivatePass = function (purchase) {
        var userDetails = this.userDetailsSubject.value;
        if (!userDetails || !userDetails.profile || !userDetails.profile.accessLevel) {
            return false;
        }
        var accessLevel = userDetails.profile.accessLevel;
        var gyms = userDetails.gyms || [];
        if (accessLevel === "SELF_GYM") {
            return purchase.plan.planType === "PLATINUM" || gyms.includes(purchase.gymUnity.id);
        }
        else {
            return accessLevel === "ALL";
        }
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.Apollo), i0.inject(i2.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
