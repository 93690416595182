import { types } from 'typed-graphqlify';

export const CANCEL_REASON = {
    id: types.number,
    reason: types.string,
};

export type CancelReason = typeof CANCEL_REASON;

export const CANCEL_REASON_QUERY = {
  listCancelReasons: [ CANCEL_REASON ],
};

export type CancelReasonQuery = typeof CANCEL_REASON_QUERY;
