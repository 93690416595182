import { types } from 'typed-graphqlify';

export const CONTRACT_CANCELATION_DATA = {
  id: types.string,
  cancelationData: {
      unitaryValue: types.number,
      residualValue: types.number,
      fineValue: types.number,
      reverseValue: types.number,
  },
  contractCancelDate: types.string,
  isCanceled: types.boolean,
  cancelReason: types.string,

};

export type ContractCancelationData = typeof CONTRACT_CANCELATION_DATA;

export const CANCEL_CONTRACT_MUTATION = {
  __params: { $purchaseId: 'String!', $cancelReason: 'String!', $timezone: 'String!' },
  cancelContract: {
    __params: { purchaseId: '$purchaseId', cancelReason: '$cancelReason', timezone: '$timezone' },
    ...CONTRACT_CANCELATION_DATA,
  },
};

export type CancelContractMutation = typeof CANCEL_CONTRACT_MUTATION;

export interface CancelContractMutationParams {
  purchaseId: string;
  cancelReason: string;
  timezone: string;
}
