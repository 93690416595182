import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NavbarComponent } from './navbar.component';
import { CollapseModule } from '@directives/collapse/collapse.module';
import { CanModule } from '@directives/can/can.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
    NavbarComponent,
  ],
  imports: [
    CommonModule,
    CollapseModule,
    RouterModule,
    CanModule,
    BsDropdownModule,
  ],
  exports: [NavbarComponent],
})
export class NavbarModule {}
