import { PURCHASE } from './client.graphql';
import { types } from 'typed-graphqlify';

export const PURCHASE_QUERY = {
  __params: { $id: 'String!', $timezone: 'String!'},
  purchase: {
    __params: { id: '$id'},
    ...PURCHASE,
  },
};

export type PurchaseQuery = typeof PURCHASE_QUERY;

export const PURCHASE_SIMPLE = {
  id: types.string,
  client: {
    enrollmentId: types.number,
    fullName: types.string,
  },
  plan: {
    name: types.string,
  },
  gymUnity: {
    name: types.string,
  },
  contractDate: types.string,
  sgaShoppingCartId: types.number,
  planValue: types.string,
  contractCancelDate: types.string,
  usedPasses: types.number,
  totalPasses: types.number,
  creatorUsername: types.string,
};

export type PurchaseSimple = typeof PURCHASE_SIMPLE;

export const PURCHASE_CANCELED_SIMPLE = {
  id: types.string,
  client: {
    enrollmentId: types.number,
    fullName: types.string,
  },
  contractDate: types.string,
  expirationDate: types.string,
  contractCancelDate: types.string,
  sgaShoppingCartId: types.number,
  usedPasses: types.number,
  totalPasses: types.number,
  cancelationResponsibleLogin: types.string,
  plan: {
    name: types.string,
  },
  gymUnity: {
    name: types.string,
  },
  fineValue: types.string,
  cancelReason: types.string,
};

export type PurchaseCanceledSimple = typeof PURCHASE_CANCELED_SIMPLE;

export interface PurchaseReport<T = PurchaseSimple> {
  totalAmount: number;
  totalPurchases: number;
  purchases: {
    results: T[];
    hasMore: number;
  };
}

export function get_PURCHASE_REPORT<T>(resultType: T): PurchaseReport<T> {
  return {
    totalAmount: types.number,
    totalPurchases: types.number,
    purchases: {
      results: [resultType],
      hasMore: types.number,
    },
  };
}

export const PURCHASE_REPORT_ALL_QUERY = {
  __params: {
    $unityId: 'Int',
    $start: 'Date',
    $end: 'Date',
    $entriesByPage: 'Int',
    $ignoreIds: '[String!]',
    $plans: '[Int!]',
  },
  purchaseReport: {
    __params: {
      unityId: '$unityId',
      start: '$start',
      end: '$end',
      entriesByPage: '$entriesByPage',
      ignoreIds: '$ignoreIds',
      plans: '$plans',
      reportType: 'ALL',
    },
    ...get_PURCHASE_REPORT(PURCHASE_SIMPLE),
  },
};

export type PurchaseReportAllQuery = typeof PURCHASE_REPORT_ALL_QUERY;

export const PURCHASE_REPORT_CANCELED_QUERY = {
  __params: {
    $unityId: 'Int',
    $start: 'Date',
    $end: 'Date',
    $entriesByPage: 'Int',
    $ignoreIds: '[String!]',
    $plans: '[Int!]',
  },
  purchaseReport: {
    __params: {
      unityId: '$unityId',
      start: '$start',
      end: '$end',
      entriesByPage: '$entriesByPage',
      ignoreIds: '$ignoreIds',
      plans: '$plans',
      reportType: 'CANCELATION',
    },
    ...get_PURCHASE_REPORT(PURCHASE_CANCELED_SIMPLE),
  },
};

export type PurchaseReportCanceledQuery = typeof PURCHASE_REPORT_CANCELED_QUERY;
