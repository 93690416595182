import { types } from 'typed-graphqlify';

export const AUTHENTICATE_USER_MUTATION = {
  __params: { $username: 'String!', $password: 'String!' },
  login: {
    __params: { username: '$username', password: '$password' },
    token: types.string,
  },
};

export type AuthenticateUserMutation = typeof AUTHENTICATE_USER_MUTATION;

export const PERMISSION = {
  code: types.string,
};

export type Permission = typeof PERMISSION;

export const USER_DETAILS = {
  id: types.number,
  accessProfileId: types.number,
  username: types.string,
  displayName: types.string,
  profile: {
    id: types.number,
    name: types.string,
    accessLevel: types.string,
    permissions: [PERMISSION],
  },
  gyms: [types.number],
};

export type UserDetails = typeof USER_DETAILS;

export const USER_DETAILS_QUERY = {
  userDetails: USER_DETAILS,
};

export type UserDetailsQuery = typeof USER_DETAILS_QUERY;
