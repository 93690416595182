import { Apollo } from 'apollo-angular';
import { toGqlMutation, toGqlQuery } from '@utils/json-util';
import { map } from 'rxjs/operators';
import { CREATE_CONTRACT_MUTATION, } from '@graphql/create-contract.graphql';
import { ADD_CONTRACT_ASSOCIATION_MUTATION, DISASSOCIATE_CONTRACT_MUTATION, REMOVE_CONTRACT_MUTATION, RESET_AND_UPDATE_CONTRACT_ASSOCIATION, SEARCH_CONTRACT_QUERY, } from '@graphql';
import { CANCEL_CONTRACT_MUTATION, } from '@graphql';
import * as moment from 'moment-timezone';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var ContractService = (function () {
    function ContractService(apollo) {
        this.apollo = apollo;
    }
    ContractService.prototype.searchContract = function (searchWord, startDate, endDate, entriesByPage, ignoreIds, searchType) {
        if (ignoreIds === void 0) { ignoreIds = []; }
        return this.apollo.query({
            query: toGqlQuery('SearchContractQuery', SEARCH_CONTRACT_QUERY),
            variables: {
                searchWord: searchWord,
                startInclusionDate: startDate && startDate.toISOString(),
                endInclusionDate: endDate && endDate.toISOString(),
                entriesByPage: entriesByPage,
                ignoreIds: ignoreIds,
                searchType: searchType,
            },
            fetchPolicy: 'no-cache',
        }).pipe(map(function (res) { return res.data.searchContract; }));
    };
    ContractService.prototype.createContract = function (contractDto) {
        return this.apollo.mutate({
            mutation: toGqlMutation('CreateContractMutation', CREATE_CONTRACT_MUTATION),
            variables: contractDto,
        }).pipe(map(function (res) { return res.data.createContract; }));
    };
    ContractService.prototype.addContractAssociation = function (gymId, contractId) {
        return this.apollo.mutate({
            mutation: toGqlMutation('AddContractAssociationMutation', ADD_CONTRACT_ASSOCIATION_MUTATION),
            variables: { gymId: gymId, contractId: contractId },
        }).pipe(map(function (res) { return res.data; }));
    };
    ContractService.prototype.resetAndSetAssociations = function (contractId, gymIds) {
        return this.apollo.mutate({
            mutation: toGqlMutation('ResetAndUpdateContractAssociation', RESET_AND_UPDATE_CONTRACT_ASSOCIATION),
            variables: {
                contractId: contractId,
                gymIds: gymIds,
            },
        }).pipe(map(function (res) { return res.data.resetAndUpdateContractAssociation; }));
    };
    ContractService.prototype.removeContract = function (contractId) {
        return this.apollo.mutate({
            mutation: toGqlMutation('RemoveContractMutation', REMOVE_CONTRACT_MUTATION),
            variables: { contractId: contractId },
        }).pipe(map(function (res) { return res.data.removeContract.message; }));
    };
    ContractService.prototype.disassociateContract = function (contractId, gymId) {
        return this.apollo.mutate({
            mutation: toGqlMutation('DisassociateContract', DISASSOCIATE_CONTRACT_MUTATION),
            variables: { contractId: contractId, gymId: gymId },
        }).pipe(map(function (res) { return res.data.disassociateContract; }));
    };
    ContractService.prototype.cancelContract = function (purchaseId, cancelReason, timezone) {
        timezone = timezone || moment.tz.guess();
        return this.apollo.mutate({
            mutation: toGqlMutation('CancelContractMutation', CANCEL_CONTRACT_MUTATION),
            variables: { purchaseId: purchaseId, cancelReason: cancelReason, timezone: timezone },
        }).pipe(map(function (res) { return res.data.cancelContract; }));
    };
    ContractService.ngInjectableDef = i0.defineInjectable({ factory: function ContractService_Factory() { return new ContractService(i0.inject(i1.Apollo)); }, token: ContractService, providedIn: "root" });
    return ContractService;
}());
export { ContractService };
