import { Injectable } from '@angular/core';
import { S3Service } from '@services/s3.service';
import { Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class S3PolicySettingsResolver implements Resolve<boolean> {
  constructor(
    private readonly s3Service: S3Service,
  ) {}

  resolve() {
    return this.s3Service.reloadPolicySettings();
  }
}
