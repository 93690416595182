import * as Sentry from '@sentry/browser';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';

import {
  AUTHENTICATE_USER_MUTATION,
  AuthenticateUserMutation,
  Purchase,
  USER_DETAILS_QUERY,
  UserDetails,
  UserDetailsQuery,
} from '@graphql';
import { StorageKeys } from '@utils/storage-keys';
import { NavigationExtras, Router } from '@angular/router';
import { AccessLevel, Credentials, PlanType } from '@models';
import { GraphQLError } from 'graphql';
import { copyOf } from '@utils/rxjs-util';
import { toGqlMutation, toGqlQuery } from '@utils/json-util';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly userDetailsSubject = new BehaviorSubject<UserDetails>(null);

  constructor(
    private apollo: Apollo,
    private router: Router,
  ) {
    this.userDetailsSubject.subscribe(userDetails => {
      if (userDetails) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: userDetails.id.toString(10),
            username: userDetails.username,
            accessProfileId: userDetails.accessProfileId,
            displayName: userDetails.displayName,
          });
        });
      } else {
        Sentry.configureScope((scope) => {
          scope.setUser({});
        });
      }
    });
  }

  isLoggedIn(): boolean {
    return localStorage.getItem(StorageKeys.AUTH_TOKEN) != null;
  }

  setUserToken(token: string) {
    localStorage.setItem(StorageKeys.AUTH_TOKEN, token);
  }

  clearUserToken() {
    localStorage.removeItem(StorageKeys.AUTH_TOKEN);
    this.userDetailsSubject.next(null);
  }

  logout(next?: string) {
    if (this.isLoggedIn()) {
      this.clearUserToken();
    }

    const navigateExtras: NavigationExtras = {
      queryParams: { next },
    };
    if (this.router.url !== '/login') {
      this.router.navigate(['/login'], next ? navigateExtras : undefined);
    }
  }

  login(credentials: Credentials): Observable<{ token: string }> {
    return this.apollo.mutate<AuthenticateUserMutation, Credentials>({
      mutation: toGqlMutation('AuthenticateUserMutation', AUTHENTICATE_USER_MUTATION),
      variables: credentials,
    }).pipe(
      map(res => res.data.login),
      tap(res => {
        if (res && res.token) {
          this.setUserToken(res.token);
          this.getUser().subscribe();
        }
      }),
      catchError(err => {
        this.clearUserToken();
        return throwError(err.graphQLErrors.map((e: GraphQLError) => e.message));
      }),
    );
  }

  getUserDetails(): Observable<UserDetails> {
    return this.apollo.query<UserDetailsQuery>({
      query: toGqlQuery('UserDetailsQuery', USER_DETAILS_QUERY),
      fetchPolicy: 'no-cache',
    }).pipe(
      map(res => res.data.userDetails),
      tap(val => this.userDetailsSubject.next(val)),
      switchMap(copyOf),
    );
  }

  getUser(): Observable<UserDetails> {
    if (this.userDetailsSubject.value) {
      return copyOf(this.userDetailsSubject.value);
    } else {
      return this.getUserDetails();
    }
  }

  getAuthToken(): string {
    return localStorage.getItem(StorageKeys.AUTH_TOKEN);
  }

  userCanActivatePass(purchase: Purchase) {
    const userDetails = this.userDetailsSubject.value;
    if (!userDetails || !userDetails.profile || !userDetails.profile.accessLevel) {
      return false;
    }

    const accessLevel = userDetails.profile.accessLevel;
    const gyms = userDetails.gyms || [];

    if (accessLevel === AccessLevel.SELF_GYM) {
      return purchase.plan.planType === PlanType.PLATINUM || gyms.includes(purchase.gymUnity.id);
    } else {
      return accessLevel === AccessLevel.ALL;
    }
  }
}
