import { Injectable } from '@angular/core';
import {
  CANCEL_REASON_QUERY,
  CancelReason,
  Purchase,
  PURCHASE_QUERY,
  PURCHASE_REPORT_ALL_QUERY,
  PURCHASE_REPORT_CANCELED_QUERY,
  PurchaseCanceledSimple,
  PurchaseQuery,
  PurchaseReport,
  PurchaseReportAllQuery,
  PurchaseReportCanceledQuery,
  PurchaseSimple,
} from '@graphql';
import * as moment from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { toGqlQuery } from '@utils/json-util';
import { pluck } from 'rxjs/operators';

type SimplePurchase = Pick<Purchase, 'usedPasses' | 'totalPasses' | 'isActive' | 'expirationDate' | 'isCanceled'>;

interface PurchaseReportParameters {
  unityId?: number;
  start?: number | string;
  end?: number | string;
  entriesByPage?: number;
  ignoreIds?: string[];
  plan?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PurchaseService {

  constructor(
    private readonly apollo: Apollo,
  ) {}

  static isPurchaseValid(purchase: SimplePurchase, timestamp?: number) {
    timestamp = timestamp || Date.now();

    if (purchase.usedPasses > purchase.totalPasses || purchase.isCanceled) {
      return false;
    } else if (purchase.usedPasses === purchase.totalPasses) {
      return purchase.isActive;
    } else {
      const formattedDate = moment(timestamp).format('YYYY-MM-DD');
      return formattedDate <= purchase.expirationDate;
    }
  }

  purchase(id: number, timezone?: string): Observable<Purchase> {
    timezone = timezone || moment.tz.guess();
    if (!id) {
      return of(null);
    }
    return this.apollo.query<PurchaseQuery>({
      query: toGqlQuery('PurchaseQuery', PURCHASE_QUERY),
      variables: { id, timezone },
      fetchPolicy: 'no-cache',
    }).pipe(
      pluck('data', 'purchase'),
    );
  }

  purchaseReportAll(
      { unityId, start, end, entriesByPage, ignoreIds, plan }: PurchaseReportParameters,
      ): Observable<PurchaseReport<PurchaseSimple>> {

    return this.apollo.query<PurchaseReportAllQuery>({
      query: toGqlQuery('PurchaseReportAllQuery', PURCHASE_REPORT_ALL_QUERY),
      variables: { unityId, start, end, entriesByPage, ignoreIds, plans: plan ? [plan] : undefined },
      fetchPolicy: 'no-cache',
    }).pipe(
      pluck('data', 'purchaseReport'),
    );
  }

  purchaseReportCanceled(
      { unityId, start, end, entriesByPage, ignoreIds, plan }: PurchaseReportParameters,
      ): Observable<PurchaseReport<PurchaseCanceledSimple>> {

    return this.apollo.query<PurchaseReportCanceledQuery>({
      query: toGqlQuery('PurchaseReportCanceledQuery', PURCHASE_REPORT_CANCELED_QUERY),
      variables: { unityId, start, end, entriesByPage, ignoreIds, plans:  plan ? [plan] : undefined },
      fetchPolicy: 'no-cache',
    }).pipe(
      pluck('data', 'purchaseReport'),
    );
  }

  listCancelReasons(): Observable<CancelReason[]> {
    return this.apollo.query<CancelReason[]>({
      query: toGqlQuery('CancelReasonQuery', CANCEL_REASON_QUERY),
      fetchPolicy: 'no-cache',
    }).pipe(
      pluck('data', 'listCancelReasons'),
    );
  }

}
