import { Injectable } from '@angular/core';
import { BreadcrumbData, Breadcrumbs, BreadcrumbState } from '@models';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {

  private queryParamsMap: Record<string, any> = {};

  constructor(
    private readonly router: Router,
  ) {}

  // Deve ser chamado pelas telas que querem ter seu estado restaurado quando são acessadas pelo breadcrumb de outra tela
  public storeQueryParams(queryParams: any) {
    const url = this.router.url.split('?')[0];
    this.queryParamsMap[url] = queryParams;
  }

  public getPreviousState(activatedRoute: ActivatedRoute): BreadcrumbState {
    const currentBc = this.getCurrentBreadcrumb(activatedRoute);
    const allRoutes = activatedRoute.snapshot.pathFromRoot;

    // Busca a rota pai mais próxima que tenha breadcrumb diferente da atual
    let i;
    for (i = allRoutes.length - 1; i >= 0; i--) {
      const route = allRoutes[i];
      const breadcrumb = route.data.breadcrumb;

      // Rotas que não tem breadcrumb definido herdarão o mesmo do pai
      if (breadcrumb && breadcrumb !== currentBc) {
        break;
      }
    }

    const routesForUrl = allRoutes
      .slice(0, i + 1)
      .map(r => r.url[0])
      .filter(routeUrl => routeUrl);

    const bc = allRoutes[i].data.breadcrumb || Breadcrumbs.HOME;
    const url = '/' + routesForUrl.join('/');
    const queryParams = this.queryParamsMap[url];

    return { url, queryParams, bc };
  }

  public getCurrentBreadcrumb(route: ActivatedRoute): BreadcrumbData {
    return route.snapshot.data.breadcrumb;
  }
}
