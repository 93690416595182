import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Contract, GYM_UNITY_QUERY, GymUnity, GymUnityQuery } from '@graphql';
import { toGqlQuery } from '@utils/json-util';
import { map } from 'rxjs/operators';
import { sortDescendingComparator } from '@utils/string-util';
import * as moment from 'moment';
import { strcmpName } from '@utils/string-util';

@Injectable({
  providedIn: 'root',
})
export class GymUnityService {
  constructor(
    private readonly apollo: Apollo,
  ) {}

  getAllGym() {
    return this.apollo.query<GymUnityQuery>({
      query: toGqlQuery('GymUnityQuery', GYM_UNITY_QUERY),
    }).pipe(
      map(res => res.data.gyms),
    );
  }

  updateGymAssociations(gyms: GymUnity[], contract: Contract, associatedGymIds: number[]) {
    // TODO 07/03/2019 o ideal seria o backend passar a data de criação dos GymUnityContracts
    const now = new Date().toISOString();

    return gyms.map(gym => {
      if (associatedGymIds && associatedGymIds.includes(gym.id)) {
        gym = {
          ...gym,
          activeGymUnityContract: {
            contract,
            auditInformation: {
              inclusionDate: now,
              modificationDate: now,
            },
          },
        };
      } else if (gym.activeGymUnityContract && gym.activeGymUnityContract.contract.id === contract.id) {
        gym = {
          ...gym,
          activeGymUnityContract: null,
        };
      }

      return gym;
    });
  }

  removeGymAssociation(gyms: GymUnity[], gymToDisassociateId: number) {
    return gyms.map(gym => {
      if (gym.id === gymToDisassociateId) {
        gym = { ...gym, activeGymUnityContract: null };
      }
      return gym;
    });
  }

  sortGymsByAssociationDateDescending(gyms: GymUnity[]) {
    return gyms.sort((first, second) => {
      const firstAssociation = first.activeGymUnityContract;
      const secondAssociation = second.activeGymUnityContract;

      const firstDate = firstAssociation && moment(firstAssociation.auditInformation.inclusionDate).format('YYYYMMDDHHmm');
      const secondDate = secondAssociation && moment(secondAssociation.auditInformation.inclusionDate).format('YYYYMMDDHHmm');

      return sortDescendingComparator(firstDate, secondDate) || strcmpName(first, second);
    });
  }
}
