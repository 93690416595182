import { types } from 'typed-graphqlify';

export interface SearchResults<T> {
  results: T[];
  entriesByPage: number;
  hasMore: number;
}

export function getSearchResults<T>(resultType: T) {
  return {
    results: [resultType],
    entriesByPage: types.number,
    hasMore: types.number,
  };
}
