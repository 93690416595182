import { graphqlify } from 'typed-graphqlify';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export function safeGet(json: any, key: string): any {
  try {
    let result = json;
    const keys = key.split('.');
    keys.forEach(k => result = result[k]);
    return result;
  } catch (e) {
    console.error('Json Parse Error:', e.message, `- Asked for [${key}] from`, json);
    return null;
  }
}

export const exists = (val: any) => typeof val !== 'undefined' && val !== null;  // exists(0) => true
export const truthy = (val: any) => typeof val !== 'undefined' && val !== null && Boolean(val);  // truthy(0) => false

export function turnApolloMutable<T>(key?: string): (immutable: any) => T {
  if (key) {
    return (immutable: any): T => JSON.parse(JSON.stringify(immutable.data[key])) as T;
  } else {
    return (immutable: any): T => JSON.parse(JSON.stringify(immutable.data)) as T;
  }
}

export function toGqlQuery(operationName: string, queryObject: Record<string, any>): DocumentNode {
  return gql(graphqlify.query(operationName, queryObject));
}

export function toGqlMutation(operationName: string, queryObject: Record<string, any>): DocumentNode {
  return gql(graphqlify.mutation(operationName, queryObject));
}
