import { types } from 'typed-graphqlify';

export const S3_POLICY = {
  policy: types.string,
  signature: types.string,
  key: types.string,
  bucketName: types.string,  // Equivalente a S3_POLICY_SETTINGS.bucket
  identifier: types.string,
};

export type S3Policy = typeof S3_POLICY;

export const GENERATE_S3_POLICY_MUTATION = {
  __params: { $numberOfPolicies: 'Int!' },
  generateS3Policy: {
    __params: { numberOfPolicies: '$numberOfPolicies' },
    ...S3_POLICY,
  },
};

export type GenerateS3PolicyMutation = typeof GENERATE_S3_POLICY_MUTATION;

export const S3_POLICY_SETTINGS = {
  region: types.string,
  bucket: types.string,  // Equivalente a S3_POLICY.bucketName
};

export type S3PolicySettings = typeof S3_POLICY_SETTINGS;

export const POLICY_SETTINGS_QUERY = {
  policySettings: S3_POLICY_SETTINGS,
};

export type PolicySettingsQuery = typeof POLICY_SETTINGS_QUERY;
