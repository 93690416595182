import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BaseLayoutComponent } from './base-layout.component';
import { NavbarModule } from '@components/navbar/navbar.module';

@NgModule({
  declarations: [
    BaseLayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NavbarModule,
  ],
  exports: [BaseLayoutComponent],
})
export class BaseLayoutModule {}
