<div
  bsModal [config]="{keyboard: true, class: 'modal-md'}"
  (onShow)="isOpen=true"
  (onHide)="isOpen=false"
  class="modal fade" tabindex="-1" role="dialog" >
  <div *ngIf="isOpen" class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header network-error">
        <img src="assets/common/ic-empty-cloud.svg" alt="Erro de conexão">
      </div>
      <div class="modal-body">
        Esta página não existe ou encontra-se indisponível. Verifique sua conexão ou tente novamente mais tarde.
      </div>
      <div class="modal-footer row no-gutters">
        <div class="col-12 col-sm-4">
          <button type="button" class="btn btn-primary btn-block" (click)="closeModal()">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>

