import { toGqlQuery } from '@utils/json-util';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  Client,
  CLIENT_EMAIL_QUERY,
  CLIENT_EXTENDED_QUERY,
  CLIENT_PHOTO_QUERY,
  CLIENT_QUERY,
  ClientDetails,
  ClientEmailQuery,
  ClientExtendedQuery,
  ClientPhotoQuery,
  ClientQuery,
  SEARCH_CLIENTS_QUERY,
  SearchClientsQuery,
  SearchResults,
} from '@graphql';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class ClientService {

  constructor(
    private readonly apollo: Apollo,
  ) {}

  searchClients(searchWord: string, entriesByPage: number, ignoreIds: number[] = [], timezone?: string): Observable<SearchResults<Client>> {
    timezone = timezone || moment.tz.guess();

    return this.apollo.query<SearchClientsQuery>({
      query: toGqlQuery('SearchClientsQuery', SEARCH_CLIENTS_QUERY),
      variables: { searchWord, entriesByPage, ignoreIds, timezone },
      fetchPolicy: 'no-cache',
    }).pipe(
      map(res => res.data.searchClient),
    );
  }

  client(id: number, timezone?: string): Observable<ClientDetails> {
    timezone = timezone || moment.tz.guess();

    if (!id) {
      return of(null);
    }

    return this.apollo.query<ClientQuery>({
      query: toGqlQuery('ClientQuery', CLIENT_QUERY),
      variables: { id , timezone},
      fetchPolicy: 'no-cache',
    }).pipe(
      map( res => res.data.client),
    );
  }

  getEmail(clientId: number): Observable<string> {
    return this.apollo.query<ClientEmailQuery>({
      query: toGqlQuery('ClientEmailQuery', CLIENT_EMAIL_QUERY),
      variables: { id: clientId },
      fetchPolicy: 'cache-first',
      context: {
        skipBatching: true,
        noBlockUi: true,
      },
    }).pipe(
      map(res => res.data.client.email),
    );
  }

  getPhoto(clientId: number) {
    return this.apollo.query<ClientPhotoQuery>({
      query: toGqlQuery('ClientPhotoQuery', CLIENT_PHOTO_QUERY),
      variables: { id: clientId },
      fetchPolicy: 'cache-first',
      context: {
        skipBatching: true,
        noBlockUi: true,
      },
    }).pipe(
      map(res => res.data.client.urlPhoto),
    );
  }

  getExtendedInformation(clientId: number) {
    return this.apollo.query<ClientExtendedQuery>({
      query: toGqlQuery('ClientExtendedQuery', CLIENT_EXTENDED_QUERY),
      variables: { id: clientId },
    }).pipe(
      map(res => res.data.client.extraInformation),
    );
  }
}
