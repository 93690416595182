import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessage, Credentials } from '@models';
import { AlertMessageService, AuthService } from '@services';
import { finalize } from 'rxjs/operators';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  credentials: Partial<Credentials> = {};
  loginForm: FormGroup;
  loginFields: FormlyFieldConfig[];
  requestOngoing = false;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertMessageService: AlertMessageService,
  ) {
    this.loginForm = new FormGroup({});
    this.loginFields = [
      {
        key: 'username',
        type: 'input',
        templateOptions: {
          type: 'text',
          placeholder: 'Usuário',
          required: true,
          addonRight: {
            class: 'fas fa-user',
          },
        },
        validation: {
          messages: {
            required: 'Campo obrigatório',
          },
        },
      },
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          type: 'password',
          placeholder: 'Senha',
          required: true,
          addonRight: {
            class: 'fas fa-lock',
          },
        },
        validation: {
          messages: {
            required: 'Campo obrigatório',
          },
        },
      },
    ];
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.navigateToNext();
    }
  }

  submitLogin() {
    if (this.requestOngoing) { return false; }

    this.requestOngoing = true;
    this.alertMessageService.clear();
    this.authService.login(this.credentials as Credentials)
      .pipe(finalize(() => this.requestOngoing = false))
      .subscribe(
        () => this.navigateToNext(),
        (err: any[]) => {
          const message = (err && err.length && err[0].message) ? err[0].message : 'Erro desconhecido...';
          this.alertMessageService.showNow(AlertMessage.error(message));
        },
      );
  }

  private async navigateToNext() {
    const next: string = this.activatedRoute.snapshot.queryParams.next;
    this.router.navigateByUrl(next ? next : '/');
  }

}
