import { BehaviorSubject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
export function screenSizeToString(size) {
    var _a;
    var sizes = (_a = {},
        _a[1] = 'xs',
        _a[2] = 'sm',
        _a[3] = 'md',
        _a[4] = 'lg',
        _a[5] = 'xl',
        _a);
    return sizes[size];
}
export function screenSizeMap(size, values, fallback) {
    if (values === void 0) { values = [0, 1, 2, 3, 4]; }
    if (fallback === void 0) { fallback = 0; }
    var _a;
    var sizes = (_a = {},
        _a[1] = values[0],
        _a[2] = values[1],
        _a[3] = values[2],
        _a[4] = values[3],
        _a[5] = values[4],
        _a);
    return sizes[size] || fallback;
}
export function screenWidthMap(w, values, fallback) {
    if (values === void 0) { values = [0, 1, 2, 3, 4]; }
    if (fallback === void 0) { fallback = 0; }
    if (w < 576) {
        return values[0] || fallback;
    }
    else if (w < 768) {
        return values[1] || fallback;
    }
    else if (w < 992) {
        return values[2] || fallback;
    }
    else if (w < 1200) {
        return values[3] || fallback;
    }
    else {
        return values[4] || fallback;
    }
}
function widthToScreensize(width) {
    return screenWidthMap(width, [1, 2, 3, 4, 5]);
}
var BreakpointsService = (function () {
    function BreakpointsService(platformId) {
        var _this = this;
        this.platformId = platformId;
        this.eventListenerObject = {
            handleEvent: function (event) {
                _this.emitterEvery.next(widthToScreensize(event.target.innerWidth));
            },
        };
        var startValue = widthToScreensize(isPlatformBrowser(this.platformId) ? window.innerWidth : 1024);
        this.emitterEvery = new BehaviorSubject(startValue);
        this.emitter = new BehaviorSubject(startValue);
        this.emitterEvery.pipe(distinctUntilChanged()).subscribe(function (val) { return _this.emitter.next(val); });
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        window.addEventListener('resize', this.eventListenerObject);
    }
    BreakpointsService.prototype.ngOnDestroy = function () {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        window.removeEventListener('resize', this.eventListenerObject);
    };
    BreakpointsService.screenSize = screenWidthMap;
    BreakpointsService.ngInjectableDef = i0.defineInjectable({ factory: function BreakpointsService_Factory() { return new BreakpointsService(i0.inject(i0.PLATFORM_ID)); }, token: BreakpointsService, providedIn: "root" });
    return BreakpointsService;
}());
export { BreakpointsService };
