import { types } from 'typed-graphqlify';

export const CLIENT_PHOTO_QUERY = {
  __params: { $id: 'Int!' },
  client: {
    __params: { id: '$id' },
    urlPhoto: types.string,
  },
};

export type ClientPhotoQuery = typeof CLIENT_PHOTO_QUERY;

export const CLIENT_EMAIL_QUERY = {
  __params: { $id: 'Int!' },
  client: {
    __params: { id: '$id' },
    email: types.string,
  },
};

export type ClientEmailQuery = typeof CLIENT_EMAIL_QUERY;
