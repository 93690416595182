import { OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { safeUnsubscribe } from '@utils/rxjs-util';
import { removeItem as ArrayUtil_removeItem, removeRepeatedInObjectArray } from '@utils/array-util';
import { AlertMessageService } from '@services';
var FormErrorComponent = (function () {
    function FormErrorComponent(router, alertMessageService) {
        this.router = router;
        this.alertMessageService = alertMessageService;
        this.autoRetrieve = false;
    }
    FormErrorComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        if (typeof this.messages === 'undefined' || this.messages == null) {
            this.messages = [];
        }
        if (this.autoRetrieve) {
            (_a = this.messages).push.apply(_a, this.alertMessageService.consume());
            this.subRouter = this.router.events.subscribe(function (event) {
                if (event instanceof NavigationEnd
                    || event instanceof NavigationCancel
                    || event instanceof NavigationError) {
                    _this.alertMessageService.clear();
                    _this.messages = removeRepeatedInObjectArray(_this.messages.concat(_this.alertMessageService.consume()), 'msg');
                }
            });
            this.subEmitter = this.alertMessageService.emitter.subscribe(function (messages) {
                _this.messages = removeRepeatedInObjectArray(_this.messages.concat(messages), 'msg');
            });
            this.subClear = this.alertMessageService.cleanMessages.subscribe(function () {
                _this.messages = [];
            });
        }
    };
    FormErrorComponent.prototype.ngOnDestroy = function () {
        safeUnsubscribe(this.subRouter);
        safeUnsubscribe(this.subEmitter);
        safeUnsubscribe(this.subClear);
    };
    FormErrorComponent.prototype.close = function (msg) {
        ArrayUtil_removeItem(this.messages, msg);
    };
    return FormErrorComponent;
}());
export { FormErrorComponent };
