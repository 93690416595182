import { types } from 'typed-graphqlify';

export const GYM_ACCESS_RECORD = {
  id: types.number,
  dateTimeUtc: types.string,
  unity: {
    name: types.string,
  },
};

export type GymAccessRecord = typeof GYM_ACCESS_RECORD;

export const PASS_ACTIVATION_WITH_RECORDS = {
  id: types.number,
  sortNumber: types.number,
  purchase: {
    totalPasses: types.number,
  },
  accessRecords: [GYM_ACCESS_RECORD],
};

export type PassActivationWithRecords = typeof PASS_ACTIVATION_WITH_RECORDS;

export const ACCESS_RECORDS_QUERY = {
  __params: { $activationId: 'Int!' },
  passActivation: {
    __params: { id: '$activationId' },
    ...PASS_ACTIVATION_WITH_RECORDS,
  },
};

export type AccessRecordsQuery = typeof ACCESS_RECORDS_QUERY;
