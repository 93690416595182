import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { toGqlQuery } from '@utils/json-util';
import { pluck } from 'rxjs/operators';
import { Plan, PLAN_QUERY, PlanQuery } from '@graphql';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlanService {

  constructor(
    private readonly apollo: Apollo,
  ) {}

  list(): Observable<Plan[]> {
    return this.apollo.query<PlanQuery>({
      query: toGqlQuery('PurchaseReportAllQuery', PLAN_QUERY),
    }).pipe(
      pluck('data', 'plans'),
    );
  }

}
