import { Apollo } from 'apollo-angular';
import { toGqlMutation } from '@utils/json-util';
import { map } from 'rxjs/operators';
import { ACTIVATE_PASS_MUTATION } from '@graphql';
import * as moment from 'moment-timezone';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var ActivationPassService = (function () {
    function ActivationPassService(apollo) {
        this.apollo = apollo;
    }
    ActivationPassService.prototype.activatePass = function (purchaseId, date, timezone) {
        timezone = timezone || moment.tz.guess();
        date = date || new Date();
        return this.apollo.mutate({
            mutation: toGqlMutation('ActivatePass', ACTIVATE_PASS_MUTATION),
            variables: { purchaseId: purchaseId, dateTimeUtc: date.toISOString(), timezone: timezone },
            fetchPolicy: 'no-cache',
        }).pipe(map(function (res) { return res.data.activatePass; }));
    };
    ActivationPassService.ngInjectableDef = i0.defineInjectable({ factory: function ActivationPassService_Factory() { return new ActivationPassService(i0.inject(i1.Apollo)); }, token: ActivationPassService, providedIn: "root" });
    return ActivationPassService;
}());
export { ActivationPassService };
