import * as tslib_1 from "tslib";
import { TemplateRef } from '@angular/core';
import { BlockUI } from 'ng-block-ui';
import { BlockUiService } from '@services';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { NetworkErrorComponent } from '@components/network-error/network-error.component';
var AppComponent = (function () {
    function AppComponent(blockUiService, _breadcrumbService, modalService, localeService) {
        var _this = this;
        this.blockUiService = blockUiService;
        this._breadcrumbService = _breadcrumbService;
        this.modalService = modalService;
        this.localeService = localeService;
        this.localeService.use('ptbr');
        this.blockUiService.emitStatus.subscribe(function (status) {
            switch (status.action) {
                case 1:
                    _this.blockUI.start();
                    break;
                case 2:
                    _this.blockUI.start(status.message);
                    break;
                case 3:
                    _this.blockUI.stop();
                    break;
                case 4:
                    _this.blockUI.stop();
                    _this.networkErrorComponent.openModal();
                    break;
            }
        });
    }
    AppComponent.prototype.openModal = function (template) {
        this.modalRef = this.modalService.show(template);
    };
    tslib_1.__decorate([
        BlockUI(),
        tslib_1.__metadata("design:type", Object)
    ], AppComponent.prototype, "blockUI", void 0);
    return AppComponent;
}());
export { AppComponent };
