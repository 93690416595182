import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CollapseComponent } from './collapse.component';

@NgModule({
  declarations: [
    CollapseComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [CollapseComponent],
})
export class CollapseModule { }
