import { alias, types } from 'typed-graphqlify';
import { getSearchResults } from '@graphql/search.graphql';
import { PurchaseStatus } from '@models';

export const CLOSEST_EXPIRATION_PURCHASE = {
  id: types.number,
  usedPasses: types.number,
  totalPasses: types.number,
  [alias('isActive', 'isActive(timezone: $timezone)')]: types.boolean,
  [alias('status', 'status(timezone: $timezone)')]: types.string as PurchaseStatus,

  plan: {
    name: types.string,
  },
};

export const CLIENT = {
  enrollmentId: types.number,
  fullName: types.string,
  documentNumber: types.string,
  closestExpirationPurchase: CLOSEST_EXPIRATION_PURCHASE,
};

CLIENT['closestExpirationPurchase(timezone: $timezone)'] = CLIENT.closestExpirationPurchase;
delete CLIENT.closestExpirationPurchase;

export type Client = typeof CLIENT;

export const SEARCH_CLIENTS_QUERY = {
  __params: { $searchWord: 'String', $entriesByPage: 'Int', $ignoreIds: '[Int!]', $timezone: 'String!' },
  searchClient: {
    __params: { searchWord: '$searchWord', entriesByPage: '$entriesByPage', ignoreIds: '$ignoreIds' },
    ...getSearchResults(CLIENT),
  },
};

export type SearchClientsQuery = typeof SEARCH_CLIENTS_QUERY;
