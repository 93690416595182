export function includesInsensitive(a: string, b: string) {
  a = a || '';
  b = b || '';
  return a.toLowerCase().includes(b.toLowerCase());
}

export function strnormalize(s: string) {
  return s.trim().toUpperCase();
}

export function strcmp(a: string, b: string) {
  if (a === undefined) {
    return b === undefined ? 0 : 1;
  }
  return a.localeCompare(
    b,
    'pt',
    {
      sensitivity: 'base',
      ignorePunctuation: true,
      numeric: true,
    },
  );
}

export function sortAscendingComparator<T extends boolean | number | string>(first: T, second: T) {
  return +(first > second) - +(first < second);
}

export function sortDescendingComparator<T extends boolean | number | string>(first: T, second: T) {
  return sortAscendingComparator(second, first);
}

export const strcmpName = (a: any, b: any) => strcmp(a.name, b.name);

export function filenameWithoutExtension(filename: string) {
  const lastDot = filename.lastIndexOf('.');

  if (lastDot > 0) {
    return filename.slice(0, lastDot);
  } else {
    return filename;
  }
}
