import { types } from 'typed-graphqlify';

export const REMOVE_CONTRACT_MUTATION = {
  __params: { $contractId: 'Int!' },
  removeContract: {
    __params: { id: '$contractId' },
    message: types.string,
  },
};

export type RemoveContractMutation = typeof REMOVE_CONTRACT_MUTATION;
