import { CANCEL_REASON_QUERY, PURCHASE_QUERY, PURCHASE_REPORT_ALL_QUERY, PURCHASE_REPORT_CANCELED_QUERY, } from '@graphql';
import * as moment from 'moment-timezone';
import { of } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { toGqlQuery } from '@utils/json-util';
import { pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var PurchaseService = (function () {
    function PurchaseService(apollo) {
        this.apollo = apollo;
    }
    PurchaseService.isPurchaseValid = function (purchase, timestamp) {
        timestamp = timestamp || Date.now();
        if (purchase.usedPasses > purchase.totalPasses || purchase.isCanceled) {
            return false;
        }
        else if (purchase.usedPasses === purchase.totalPasses) {
            return purchase.isActive;
        }
        else {
            var formattedDate = moment(timestamp).format('YYYY-MM-DD');
            return formattedDate <= purchase.expirationDate;
        }
    };
    PurchaseService.prototype.purchase = function (id, timezone) {
        timezone = timezone || moment.tz.guess();
        if (!id) {
            return of(null);
        }
        return this.apollo.query({
            query: toGqlQuery('PurchaseQuery', PURCHASE_QUERY),
            variables: { id: id, timezone: timezone },
            fetchPolicy: 'no-cache',
        }).pipe(pluck('data', 'purchase'));
    };
    PurchaseService.prototype.purchaseReportAll = function (_a) {
        var unityId = _a.unityId, start = _a.start, end = _a.end, entriesByPage = _a.entriesByPage, ignoreIds = _a.ignoreIds, plan = _a.plan;
        return this.apollo.query({
            query: toGqlQuery('PurchaseReportAllQuery', PURCHASE_REPORT_ALL_QUERY),
            variables: { unityId: unityId, start: start, end: end, entriesByPage: entriesByPage, ignoreIds: ignoreIds, plans: plan ? [plan] : undefined },
            fetchPolicy: 'no-cache',
        }).pipe(pluck('data', 'purchaseReport'));
    };
    PurchaseService.prototype.purchaseReportCanceled = function (_a) {
        var unityId = _a.unityId, start = _a.start, end = _a.end, entriesByPage = _a.entriesByPage, ignoreIds = _a.ignoreIds, plan = _a.plan;
        return this.apollo.query({
            query: toGqlQuery('PurchaseReportCanceledQuery', PURCHASE_REPORT_CANCELED_QUERY),
            variables: { unityId: unityId, start: start, end: end, entriesByPage: entriesByPage, ignoreIds: ignoreIds, plans: plan ? [plan] : undefined },
            fetchPolicy: 'no-cache',
        }).pipe(pluck('data', 'purchaseReport'));
    };
    PurchaseService.prototype.listCancelReasons = function () {
        return this.apollo.query({
            query: toGqlQuery('CancelReasonQuery', CANCEL_REASON_QUERY),
            fetchPolicy: 'no-cache',
        }).pipe(pluck('data', 'listCancelReasons'));
    };
    PurchaseService.ngInjectableDef = i0.defineInjectable({ factory: function PurchaseService_Factory() { return new PurchaseService(i0.inject(i1.Apollo)); }, token: PurchaseService, providedIn: "root" });
    return PurchaseService;
}());
export { PurchaseService };
