import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import { BlockUIModule } from 'ng-block-ui';
import { FormlyBootstrapModule} from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';

import { ApolloConfigModule } from '@utils/apollo-config/apollo-config.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor, BlockUiInterceptor } from '@interceptors';
import { BaseLayoutModule } from '@components/base-layout/base-layout.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginModule } from '@views/login/login.module';
import { NavbarModule } from '@components/navbar/navbar.module';
import { NgxMaskModule } from 'ngx-mask';
import { NotFoundComponent } from '@views/not-found/not-found.component';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ModalModule } from 'ngx-bootstrap';
import { NetworkErrorModule } from '@components/network-error/network-error.module';
import { ptBrLocale } from 'ngx-bootstrap/locale';

import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { SentryErrorHandler } from './sentry.errorhandler';
import { UnauthorizedComponent } from '@views/unauthorized/unauthorized.component';

registerLocaleData(ptBr);
defineLocale('ptbr', ptBrLocale);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    UnauthorizedComponent,
  ],
  imports: [
    AccordionModule.forRoot(),
    ApolloConfigModule,
    AppRoutingModule,
    BlockUIModule.forRoot({
      message: 'Carregando...',
    }),
    BrowserAnimationsModule,
    BrowserModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    FormlyBootstrapModule,
    FormlyModule.forRoot(),
    HttpClientModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),

    BaseLayoutModule,
    NavbarModule,

    LoginModule,
    NetworkErrorModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlockUiInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
