import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessage } from '@models';
import { AlertMessageService, AuthService } from '@services';
import { finalize } from 'rxjs/operators';
var LoginComponent = (function () {
    function LoginComponent(authService, router, activatedRoute, alertMessageService) {
        this.authService = authService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.alertMessageService = alertMessageService;
        this.credentials = {};
        this.requestOngoing = false;
        this.loginForm = new FormGroup({});
        this.loginFields = [
            {
                key: 'username',
                type: 'input',
                templateOptions: {
                    type: 'text',
                    placeholder: 'Usuário',
                    required: true,
                    addonRight: {
                        class: 'fas fa-user',
                    },
                },
                validation: {
                    messages: {
                        required: 'Campo obrigatório',
                    },
                },
            },
            {
                key: 'password',
                type: 'input',
                templateOptions: {
                    type: 'password',
                    placeholder: 'Senha',
                    required: true,
                    addonRight: {
                        class: 'fas fa-lock',
                    },
                },
                validation: {
                    messages: {
                        required: 'Campo obrigatório',
                    },
                },
            },
        ];
    }
    LoginComponent.prototype.ngOnInit = function () {
        if (this.authService.isLoggedIn()) {
            this.navigateToNext();
        }
    };
    LoginComponent.prototype.submitLogin = function () {
        var _this = this;
        if (this.requestOngoing) {
            return false;
        }
        this.requestOngoing = true;
        this.alertMessageService.clear();
        this.authService.login(this.credentials)
            .pipe(finalize(function () { return _this.requestOngoing = false; }))
            .subscribe(function () { return _this.navigateToNext(); }, function (err) {
            var message = (err && err.length && err[0].message) ? err[0].message : 'Erro desconhecido...';
            _this.alertMessageService.showNow(AlertMessage.error(message));
        });
    };
    LoginComponent.prototype.navigateToNext = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var next;
            return tslib_1.__generator(this, function (_a) {
                next = this.activatedRoute.snapshot.queryParams.next;
                this.router.navigateByUrl(next ? next : '/');
                return [2];
            });
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
