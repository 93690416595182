import { AuthService } from '@services';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/common";
var AuthGuard = (function () {
    function AuthGuard(authService, location) {
        this.authService = authService;
        this.location = location;
        this.canActivateChild = this.canActivate;
    }
    AuthGuard.prototype.canActivate = function () {
        if (this.authService.isLoggedIn()) {
            return true;
        }
        else {
            this.authService.logout(this.location.path(true));
        }
    };
    AuthGuard.prototype.canLoad = function () {
        return this.authService.isLoggedIn();
    };
    AuthGuard.prototype.resolve = function () {
        return this.authService.getUserDetails();
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.AuthService), i0.inject(i2.Location)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
