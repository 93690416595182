import * as i0 from "./unauthorized.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./unauthorized.component";
var styles_UnauthorizedComponent = [i0.styles];
var RenderType_UnauthorizedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnauthorizedComponent, data: {} });
export { RenderType_UnauthorizedComponent as RenderType_UnauthorizedComponent };
export function View_UnauthorizedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["N\u00E3o autorizado"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "card-body d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "my-7"], ["src", "assets/bt/img/unauthorized.svg"]], null, null, null, null, null))], null, null); }
export function View_UnauthorizedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_UnauthorizedComponent_0, RenderType_UnauthorizedComponent)), i1.ɵdid(1, 49152, null, 0, i2.UnauthorizedComponent, [], null, null)], null, null); }
var UnauthorizedComponentNgFactory = i1.ɵccf("ng-component", i2.UnauthorizedComponent, View_UnauthorizedComponent_Host_0, {}, {}, []);
export { UnauthorizedComponentNgFactory as UnauthorizedComponentNgFactory };
