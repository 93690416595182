import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { Contract } from '@graphql/contract.graphql';
import { toGqlMutation, toGqlQuery } from '@utils/json-util';
import { map } from 'rxjs/operators';
import {
    CREATE_CONTRACT_MUTATION,
    CreateContractDTO,
    CreateContractMutation,
} from '@graphql/create-contract.graphql';
import {
  ADD_CONTRACT_ASSOCIATION_MUTATION,
  AddContractAssociationMutation,
  AddContractAssociationParams,
  ContractCancelationData,
  DISASSOCIATE_CONTRACT_MUTATION,
  DisassociateContractMutation,
  DisassociatedContract,
  REMOVE_CONTRACT_MUTATION,
  RemoveContractMutation,
  RESET_AND_UPDATE_CONTRACT_ASSOCIATION,
  ResetAndUpdateContractAssociation,
  SEARCH_CONTRACT_QUERY,
  SearchContractParams,
  SearchContractQuery,
  SearchResults,
} from '@graphql';
import { ContractSearchType } from '@models';
import {
    CANCEL_CONTRACT_MUTATION,
    CancelContractMutation,
    CancelContractMutationParams,
} from '@graphql';
import * as moment from 'moment-timezone';

@Injectable({
    providedIn: 'root',
})
export class ContractService {
    constructor(
        private readonly apollo: Apollo,
    ) { }

    searchContract(
        searchWord: string,
        startDate: Date,
        endDate: Date,
        entriesByPage: number,
        ignoreIds: number[] = [],
        searchType?: ContractSearchType,
    ): Observable<SearchResults<Contract>> {
        return this.apollo.query<SearchContractQuery, SearchContractParams>({
            query: toGqlQuery('SearchContractQuery', SEARCH_CONTRACT_QUERY),
            variables: {
                searchWord,
                startInclusionDate: startDate && startDate.toISOString(),
                endInclusionDate: endDate && endDate.toISOString(),
                entriesByPage,
                ignoreIds,
                searchType,
            },
            fetchPolicy: 'no-cache',
        }).pipe(
            map(res => res.data.searchContract),
        );
    }

    createContract(contractDto: CreateContractDTO) {

        return this.apollo.mutate<CreateContractMutation>({
            mutation: toGqlMutation('CreateContractMutation', CREATE_CONTRACT_MUTATION),
            variables:  contractDto,
        }).pipe(
            map (res => res.data.createContract ),
        );
    }

    addContractAssociation(gymId: number, contractId: number) {
        return this.apollo.mutate<AddContractAssociationMutation, AddContractAssociationParams>({
            mutation: toGqlMutation('AddContractAssociationMutation', ADD_CONTRACT_ASSOCIATION_MUTATION),
            variables: { gymId, contractId },
        }).pipe(
            map(res => res.data),
        );
    }

    resetAndSetAssociations(contractId: number, gymIds: number[]) {
        return this.apollo.mutate<ResetAndUpdateContractAssociation>({
            mutation: toGqlMutation('ResetAndUpdateContractAssociation',  RESET_AND_UPDATE_CONTRACT_ASSOCIATION),
            variables: {
                contractId,
                gymIds,
            },
        }).pipe(
            map( res => res.data.resetAndUpdateContractAssociation ),
        );
    }

    removeContract(contractId: number) {
        return this.apollo.mutate<RemoveContractMutation>({
            mutation: toGqlMutation('RemoveContractMutation', REMOVE_CONTRACT_MUTATION),
            variables: { contractId },
        }).pipe(
            map(res => res.data.removeContract.message),
        );
    }

    disassociateContract(contractId: number, gymId: number): Observable<DisassociatedContract> {
        return this.apollo.mutate<DisassociateContractMutation>({
          mutation: toGqlMutation('DisassociateContract', DISASSOCIATE_CONTRACT_MUTATION),
          variables: { contractId, gymId },
        }).pipe(
          map(res => res.data.disassociateContract),
        );
    }

    cancelContract(purchaseId: string, cancelReason: string, timezone?: string): Observable<ContractCancelationData> {
        timezone = timezone || moment.tz.guess();
        return this.apollo.mutate<CancelContractMutation, CancelContractMutationParams>({
            mutation: toGqlMutation('CancelContractMutation', CANCEL_CONTRACT_MUTATION),
            variables: { purchaseId, cancelReason, timezone },
        }).pipe(
            map(res => res.data.cancelContract),
        );
    }
}
