import { GymUnityService } from '@services';
import * as i0 from "@angular/core";
import * as i1 from "../services/gym-unity.service";
var GymsResolver = (function () {
    function GymsResolver(gymUnityService) {
        this.gymUnityService = gymUnityService;
    }
    GymsResolver.prototype.resolve = function () {
        return this.gymUnityService.getAllGym();
    };
    GymsResolver.ngInjectableDef = i0.defineInjectable({ factory: function GymsResolver_Factory() { return new GymsResolver(i0.inject(i1.GymUnityService)); }, token: GymsResolver, providedIn: "root" });
    return GymsResolver;
}());
export { GymsResolver };
