export const enum BlockUIAction {
  NOOP,
  START,
  START_CUSTOM_MESSAGE,
  STOP,
  NETWORK_ERROR,
}

export type BlockUIStatus =
  { action: BlockUIAction.START_CUSTOM_MESSAGE, message: string } |
  { action: BlockUIAction.NOOP | BlockUIAction.START | BlockUIAction.STOP | BlockUIAction.NETWORK_ERROR };

export const NO_BLOCK_UI_HTTP_HEADER = 'X-NO-BLOCK-UI';
