import { types } from 'typed-graphqlify';

export const CONTRACT = {
    id: types.number,
    path: types.string,
    name: types.string,
    hasPurchase: types.boolean,
    auditInformation: {
        inclusionDate: types.string,
        modificationDate: types.string,
    },
    lastAssociationDate: types.string,
};

export type Contract = typeof CONTRACT;
