import { UserDetails } from '@graphql';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from '@angular/router';

import { AuthService } from '@services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CanGuard implements CanActivate, CanActivateChild {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivateChild = this.canActivate;

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean|UrlTree> {
    return this.authService.getUser().pipe(
      map((user: UserDetails) => {
        const can: string[] = route.data.can;
        if (!can || !can.length) {
          return true;
        }

        if (user && user.profile && user.profile.permissions) {
          const permissions = user.profile.permissions.map(p => p.code);
          if (can.some(p => permissions.includes(p))) {
            return true;
          }
        }

        return this.router.parseUrl('/unauthorized');
      }),
    );
  }

}
