import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, ViewChild } from '@angular/core';

@Component({
  selector: 'app-network-error',
  templateUrl: './network-error.component.html',
  styleUrls: ['./network-error.component.scss'],
})
export class NetworkErrorComponent {
  @ViewChild(ModalDirective) modal: ModalDirective;
  isOpen = false;

  openModal() {
    this.modal.show();
  }

  closeModal() {
    this.modal.hide();
  }
}
