import * as tslib_1 from "tslib";
import { Apollo } from 'apollo-angular';
import { GYM_UNITY_QUERY } from '@graphql';
import { toGqlQuery } from '@utils/json-util';
import { map } from 'rxjs/operators';
import { sortDescendingComparator } from '@utils/string-util';
import * as moment from 'moment';
import { strcmpName } from '@utils/string-util';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var GymUnityService = (function () {
    function GymUnityService(apollo) {
        this.apollo = apollo;
    }
    GymUnityService.prototype.getAllGym = function () {
        return this.apollo.query({
            query: toGqlQuery('GymUnityQuery', GYM_UNITY_QUERY),
        }).pipe(map(function (res) { return res.data.gyms; }));
    };
    GymUnityService.prototype.updateGymAssociations = function (gyms, contract, associatedGymIds) {
        var now = new Date().toISOString();
        return gyms.map(function (gym) {
            if (associatedGymIds && associatedGymIds.includes(gym.id)) {
                gym = tslib_1.__assign({}, gym, { activeGymUnityContract: {
                        contract: contract,
                        auditInformation: {
                            inclusionDate: now,
                            modificationDate: now,
                        },
                    } });
            }
            else if (gym.activeGymUnityContract && gym.activeGymUnityContract.contract.id === contract.id) {
                gym = tslib_1.__assign({}, gym, { activeGymUnityContract: null });
            }
            return gym;
        });
    };
    GymUnityService.prototype.removeGymAssociation = function (gyms, gymToDisassociateId) {
        return gyms.map(function (gym) {
            if (gym.id === gymToDisassociateId) {
                gym = tslib_1.__assign({}, gym, { activeGymUnityContract: null });
            }
            return gym;
        });
    };
    GymUnityService.prototype.sortGymsByAssociationDateDescending = function (gyms) {
        return gyms.sort(function (first, second) {
            var firstAssociation = first.activeGymUnityContract;
            var secondAssociation = second.activeGymUnityContract;
            var firstDate = firstAssociation && moment(firstAssociation.auditInformation.inclusionDate).format('YYYYMMDDHHmm');
            var secondDate = secondAssociation && moment(secondAssociation.auditInformation.inclusionDate).format('YYYYMMDDHHmm');
            return sortDescendingComparator(firstDate, secondDate) || strcmpName(first, second);
        });
    };
    GymUnityService.ngInjectableDef = i0.defineInjectable({ factory: function GymUnityService_Factory() { return new GymUnityService(i0.inject(i1.Apollo)); }, token: GymUnityService, providedIn: "root" });
    return GymUnityService;
}());
export { GymUnityService };
