import { CONTRACT } from './contract.graphql';

export interface CreateContractDTO {
    data: {
        path: string;
        name: string;
        associatedGymIds: number[];
    };
}

export const CREATE_CONTRACT_MUTATION = {
    __params: { $data: 'CreateContractDTO' },
    createContract: {
        __params: {
            createContractDTO: '$data',
        },
        ...CONTRACT,
    },
};

export type CreateContractMutation = typeof CREATE_CONTRACT_MUTATION;
