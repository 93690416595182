import { PurchaseService } from '@services';
import { Router } from '@angular/router';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/purchase.service";
import * as i2 from "@angular/router";
var CancelPurchaseResolver = (function () {
    function CancelPurchaseResolver(purchaseService, router) {
        this.purchaseService = purchaseService;
        this.router = router;
    }
    CancelPurchaseResolver.prototype.resolve = function (route) {
        var _this = this;
        var _a = route.params, clientId = _a.clientId, purchaseId = _a.purchaseId;
        return this.purchaseService
            .purchase(purchaseId)
            .pipe(mergeMap(function (purchase) {
            if (!purchase || purchase.isCanceled || !purchase.userCancelPermission.canCancel) {
                return _this.goToClientPage(clientId);
            }
            else {
                return of(purchase);
            }
        }), catchError(function () { return _this.goToClientPage(clientId); }));
    };
    CancelPurchaseResolver.prototype.goToClientPage = function (clientId) {
        var promise = this.router.navigate(['/client', clientId]);
        return from(promise).pipe(map(function () { return null; }));
    };
    CancelPurchaseResolver.ngInjectableDef = i0.defineInjectable({ factory: function CancelPurchaseResolver_Factory() { return new CancelPurchaseResolver(i0.inject(i1.PurchaseService), i0.inject(i2.Router)); }, token: CancelPurchaseResolver, providedIn: "root" });
    return CancelPurchaseResolver;
}());
export { CancelPurchaseResolver };
