<nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark font-title">
  <a id="navbar-home" class="navbar-brand" routerLink="/" (click)="onClickHome()">
    <img src="assets/bt/logo/portrait.svg" alt="Logo da Bodytech">
  </a>
  <button class="navbar-toggler" type="button" (click)="navLinks.toggle()" aria-controls="navbarSupportedContent"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div id="navbarSupportedContent" #navLinks app-collapse [animation]="!screenLG" [expanded]="expanded"
    (expandedChange)="onExpandedChange($event)" class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto">
      <ng-container *ngFor="let link of navbarItems">
        <ng-container *can="link.permission">
          <li
              dropdown
              #dropdown="bs-dropdown"
              *ngIf="link.children; else withoutChildren"
              (mouseenter)="screenLG && dropdown.show()"
              (mouseleave)="screenLG && dropdown.hide()"
              class="nav-item dropdown">
            <div class="position-fixed-lg" routerLinkActive="active">
              <div
                class="nav-link dropdown-toggle no-caret"
                dropdownToggle
              >{{link.text}}</div>
              <div class="dropdown-menu mr-3" *dropdownMenu>
                <a
                  *ngFor="let child of link.children"
                  class="dropdown-item"
                  routerLinkActive="active"
                  [routerLink]="child.url"
                  (click)="!screenLG && navLinks.close()"
                >{{child.text}}</a>
              </div>
            </div>
          </li>
          <ng-template #withoutChildren>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                [routerLink]="link.url"
                (click)="!screenLG && navLinks.close()"
              >{{link.text}}</a>
            </li>
          </ng-template>
        </ng-container>
      </ng-container>
    </ul>
    <div dropdown [isDisabled]="!screenLG">
      <ng-container *ngIf="userDetails$ | async as userDetails">
        <div class="divider divider-md-down"></div>
        <div class="user-menu"
            dropdownToggle
            tabindex="-1">
          <div class="divider divider-lg-only"></div>
          <!-- <div class="user-avatar">
            <img alt="Perfil do usuário" class="user-pic rounded-circle" [src]="userDetails.photo || 'assets/common/default-user-image.png'">
          </div> -->
          <div class="user-info">
            <div class="user-name">{{userDetails.displayName}}</div>
            <div class="gym-unit">{{userDetails.profile.name}}</div>
          </div>
          <div class="caret"><i class="fa fa-caret-down"></i></div>
        </div>
      </ng-container>
      <div class="position-fixed" [hidden]="!screenLG">
        <div *dropdownMenu
            class="dropdown-menu logout-dropdown"
            [title]="appVersion"
            role="menu">
          <button type="button" class="dropdown-item" (click)="logout()">Sair</button>
        </div>
      </div>
    </div>
    <ul class="navbar-nav logout-nav">
      <li class="nav-item">
        <div class="nav-link">
          <a class="btn btn-sm btn-block btn-outline-light" href="javascript:void(0)" (click)="logout()">Sair</a>
        </div>
      </li>
    </ul>
  </div>
</nav>
<div class="navbar-spacer"></div>
