import { alias, types } from 'typed-graphqlify';
import { PurchaseStatus } from '@models';

export const PASS_ACTIVATION = {
  id: types.number,
  sortNumber: types.number,
  dateTimeUtc: types.string,
  timezone: types.string,
};

export type PassActivation = typeof PASS_ACTIVATION;

export const PURCHASE = {
    id: types.string,
    sgaShoppingCartId: types.string,
    expirationDate: types.string,
    contractDate: types.string,
    usedPasses: types.number,
    totalPasses: types.number,
    activations: [PASS_ACTIVATION],
    planValue: types.string,
    userCancelPermission: {
        canCancel: types.boolean,
        reason: types.string,
    },
    cancelationData: {
        unitaryValue: types.number,
        residualValue: types.number,
        fineValue: types.number,
        reverseValue: types.number,
    },
    isCanceled: types.boolean,
    cancelReason: types.string,
    contractCancelDate: types.string,
    [alias('status', 'status(timezone: $timezone)')]: types.string as PurchaseStatus,
    [alias('isActive', 'isActive(timezone: $timezone)')]: types.boolean,
    plan: {
        name: types.string,
        planType: types.string,
        amountOfPasses: types.number,
    },
    gymUnity: {
        id: types.number,
        name: types.string,
    },
    contract: {
        id: types.number,
        path: types.string,
        name: types.string,
    },
};

export type Purchase = typeof PURCHASE;

export const CLIENT_DETAILS = {
    enrollmentId: types.number,
    fullName: types.string,
    documentNumber: types.string,
    purchases: [PURCHASE],
};

export type ClientDetails = typeof CLIENT_DETAILS;

export const CLIENT_QUERY = {
    __params: { $id: 'Int!', $timezone: 'String!'},
    client: {
        __params: { id: '$id'},
        ...CLIENT_DETAILS,
    },
};

export type ClientQuery = typeof CLIENT_QUERY;
