import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  GENERATE_S3_POLICY_MUTATION,
  GenerateS3PolicyMutation,
  POLICY_SETTINGS_QUERY,
  PolicySettingsQuery,
  S3Policy, S3PolicySettings,
} from '@graphql';
import { toGqlMutation, toGqlQuery } from '@utils/json-util';
import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BlockUiService } from '@services';

/**
 * Classe de serviços para fazer upload de arquivo no AWS S3
 */
@Injectable({
  providedIn: 'root',
})
export class S3Service {

  private policySettings: S3PolicySettings;

  constructor(
    private readonly apollo: Apollo,
    private readonly blockUiService: BlockUiService,
  ) { }

  reloadPolicySettings(force = false) {
    if (!force && this.policySettings) {
      return;
    }

    return this.apollo.query<PolicySettingsQuery>({
      query: toGqlQuery('PolicySettingsQuery', POLICY_SETTINGS_QUERY),
    }).pipe(
      map(res => {
        this.policySettings = res.data.policySettings;
        return !!this.policySettings;
       }),
    );
  }

  getS3BucketUrl() {
    const { region, bucket } = this.policySettings;
    return `https://s3.dualstack.${region}.amazonaws.com/${bucket}`;
  }

  uploadFile(file: File, bucketPath: string, blockUi = true): Observable<string> {
    return this.generateS3Policy().pipe(
      mergeMap(async (policy) => {
        await this.uploadFileToS3(bucketPath, policy, file, blockUi);
        return this.getS3FilePath(bucketPath, policy.identifier, file.name);
      }),
    );
  }

  generateS3Policy(): Observable<S3Policy> {
    return this.apollo.mutate<GenerateS3PolicyMutation>({
      mutation: toGqlMutation('GenerateS3Policy', GENERATE_S3_POLICY_MUTATION),
      variables: { numberOfPolicies: 1 },
    }).pipe(
      map(res => res.data.generateS3Policy[0]),
    );
  }

  private uploadFileToS3(s3BucketPath: string, policy: S3Policy, file: File, blockUi: boolean) {
    const s3Url = this.getS3BucketUrl();
    const s3FilePath = this.getS3FilePath(s3BucketPath, policy.identifier, file.name);
    const filename = this.sanitizeFilename(file.name);

    const formData = this.createFormData([
      [ 'key',                   s3FilePath ],
      [ 'AWSAccessKeyId',        policy.key ],

      [ 'acl',                   'public-read' ],
      [ 'policy',                policy.policy ],
      [ 'signature',             policy.signature ],

      [ 'Content-Type',          file.type ],
      [ 'Content-Disposition',   `filename="${filename}"` ],
      [ 'success_action_status', '201' ],
      [ 'file',                  file ],
    ]);

    return this.httpRequest('POST', s3Url, formData, blockUi);
  }

  private getS3FilePath(s3BucketPath: string, identifier: string, filename: string) {
    const extension = this.fileExtension(filename);
    return `${s3BucketPath}/${identifier}${extension}`;
  }

  private fileExtension(filename: string) {
    const lastDot = filename.lastIndexOf('.');

    if (lastDot < 0) {
      return '';
    }

    return filename.substr(lastDot);
  }

  private sanitizeFilename(filename: string) {
    return filename.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  private createFormData(entries: Array<[string, any]>) {
    const formData = new FormData();
    for (const [key, value] of entries) {
      formData.append(key, value);
    }
    return formData;
  }

  private httpRequest(method: string, url: string, formData: FormData, blockUi: boolean) {
    return new Promise((resolve, reject) => {
      if (blockUi) {
        this.blockUiService.increment();
      }

      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) {
          return;
        } else if (xhr.status >= 200 && xhr.status < 400) {
          resolve(xhr.response);
        } else {
          reject(xhr.response);
        }

        if (blockUi) {
          this.blockUiService.decrement();
        }
      };

      xhr.open(method, url, true);
      xhr.send(formData);
    });
  }
}
