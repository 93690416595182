import { Apollo } from 'apollo-angular';
import { toGqlQuery } from '@utils/json-util';
import { pluck } from 'rxjs/operators';
import { PLAN_QUERY } from '@graphql';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var PlanService = (function () {
    function PlanService(apollo) {
        this.apollo = apollo;
    }
    PlanService.prototype.list = function () {
        return this.apollo.query({
            query: toGqlQuery('PurchaseReportAllQuery', PLAN_QUERY),
        }).pipe(pluck('data', 'plans'));
    };
    PlanService.ngInjectableDef = i0.defineInjectable({ factory: function PlanService_Factory() { return new PlanService(i0.inject(i1.Apollo)); }, token: PlanService, providedIn: "root" });
    return PlanService;
}());
export { PlanService };
